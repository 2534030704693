import {
  ControllerManagerListIdsDocument,
  ControllerManagerListQuery,
  ControllerManagerListQueryVariables,
} from '$typings/graphql-codegen';
import {
  IReactGridSelectableRowsConfig,
  rowKey,
} from '$components/grid/react-grid/grid.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import React, { useRef, useState } from 'react';
import { mutate } from '$lib/hooks/fetch-utillities';
import { isSomething } from '$lib/helpers';
import { SelectActions } from '../modules/select-actions';
import { UseGridDataReturnType } from "$components/grid/react-grid/gridhelper";
import { ControllerService } from '$services/controllerService';

type ControllerManagerListRow =
  ControllerManagerListQuery['elasticSearchPages']['controllerManager']['data']['edges'][0];

export const useControllerListSelectableRowsConfig = (
  variables: ControllerManagerListQueryVariables,
  gridData: UseGridDataReturnType<ControllerManagerListRow>,
  navigateToCreateNewSites: (controllerId: string | string[]) => unknown,
  navigateToAttachToSites: (controllerId: string | string[]) => unknown,
  aureliaControllerService: ControllerService,
  selectedRowsChanged?: Function,
): IReactGridSelectableRowsConfig<ControllerManagerListRow> => {
  const [t] = useCaseInsensitiveTranslation();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const ref = useRef<ControllerManagerListQueryVariables>();
  const [lastFetchedRowIds, setLastFetchRowIds] = useState<
    rowKey[] | undefined
  >();

  const isControllerAssignedDict = Object.assign(
    {},
    ...(gridData.data
      ?.filter((d) => isSomething(d?.controllerId))
      .map((d) => ({ [d.controllerId]: isSomething(d.site?.id) })) ?? [])
  );
  
  const fetchAllControllerIds = async () => {
    if (ref.current === variables && lastFetchedRowIds) {
      return lastFetchedRowIds;
    }
    const result = await mutate(ControllerManagerListIdsDocument, variables);
    const rowKeys = result.elasticSearchPages.controllerManager.data.edges.map(
      (c) => c.controllerId
    );
    ref.current = variables;
    setLastFetchRowIds(rowKeys);
    return rowKeys;
  };

  const _selectedRowsChanged = (selectedRows: rowKey[]) => {
    setSelectedRows(selectedRows as number[]);
    selectedRowsChanged && selectedRowsChanged(selectedRows);
  };

  const showDetach = selectedRows.some((r) => isControllerAssignedDict[r]);
  const clearSelection = () => _selectedRowsChanged([]);

  return {
    rowKey: (c) => (isSomething(c?.controllerId) ? [c.controllerId] : []),
    selectedRowsChanged: _selectedRowsChanged,
    selectText: t(
      'UI_ControllerManager_ControllerList_SelectionBar_Description'
    ),
    fetchAllSelectableRowIds: fetchAllControllerIds,
    selectedRows: selectedRows,
    actionButtons: (
      <SelectActions
        selectedControllerIds={selectedRows}
        showDetach={showDetach}
        clearSelectedIds={clearSelection}
        navigateToAttachToSites={navigateToAttachToSites}
        navigateToCreateNewSites={navigateToCreateNewSites}
        aureliaControllerService={aureliaControllerService}
      />
    ),
  };
};
