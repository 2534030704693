import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ControllerService } from '$services/controllerService';
import {
  ControllerManager,
  IControllerManagerProps,
} from './controllermanager';
import { NotificationService } from '$services/notificationService';
import { ReactWrapper } from '$pages/common/react-wrapper';
import MuiThemeProvider from '$components/mui-theme-provider/mui-theme-provider';
import { ProvideControllerCommandService } from '$lib/hooks/useControllerCommandService';

export interface ControllerDetailsURLParams {
  serial?: string;
  assigned?: string;
  controllerType?: string;
  buid?: number;
}

@autoinject()
export class ControllerManagerWrapper extends ReactWrapper<
  IControllerManagerProps,
  ControllerDetailsURLParams
> {
  constructor(
    router: Router,
    controllerService: ControllerService,
    notificationService: NotificationService
  ) {
    super(
      ControllerManager,
      (params) => ({
        aureliaRouter: router,
        aureliaControllerService: controllerService,
        aureliaNotificationService: notificationService,
        params: params,
      }),
      [MuiThemeProvider, ProvideControllerCommandService]
    );
  }
}
export default ControllerManagerWrapper;
