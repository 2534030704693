import React, { FC } from 'react';

interface RadioButtonItemProps<T> {
  value: T;
  selectedOption: T | undefined;
  onChange: (option: T) => void;
  text?: string;
  disabled?: boolean;
}

const RadioButtonItem: FC<RadioButtonItemProps<any>> = ({ 
  value, 
  selectedOption, 
  onChange, 
  text, 
  disabled 
}) => {
  return (
    <label key={value} style={{display: "flex", flexDirection:"row"}}>
      <input
        type="radio"
        value={value}
        checked={selectedOption === value}
        onChange={() => onChange(value)}
        disabled={disabled}
      />
      <span className='radio-button-item-text'>{text}</span>
    </label>
  );
};

export default RadioButtonItem;