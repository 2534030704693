import React, { FC, } from 'react';
import Map from '$components/map/map.react'
import { IControllerMapMarker } from '$interfaces/application/imapmarker'
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import { MarkerIconEnum } from '$typings/graphql-codegen'
import Card from '$components/cards/card/card.react'
import CardHeader from '$components/cards/card-header/card-header.react'
import ControllerBackupViewer from '$pages/controllerbackupviewer/controllerbackupviewer.react';
import './controller-config.css'
import { ControllerConfigValues } from './ControllerConfigValues';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { formatDate } from '$lib/dateHelpers';
import { BackupConfigValues, BackupViewMode } from './BackupConfigValues';

interface ControllerConfigProps {
  title?: string
  controllerConfigValues: ControllerConfigValues
  backupConfigValues?: BackupConfigValues
}

const ControllerConfig: FC<ControllerConfigProps> = ({
  controllerConfigValues,
  title,
  backupConfigValues

}) => {
  const [t] = useCaseInsensitiveTranslation();

    const mapMarker: IControllerMapMarker = {
      markerIconEnum: MarkerIconEnum.ControllerOnline,
      markerType: 'controller',
      controllerId: controllerConfigValues.controllerId!,
      siteId: controllerConfigValues.siteId.value!,
      title: controllerConfigValues?.name.value as string ?? title,
      lat: controllerConfigValues?.lat ?? 0,
      lng: controllerConfigValues?.lng ?? 0,
      accuracy: controllerConfigValues.accuracy ?? 0
    }

  function setBackupViewMode(backupConfigValues: BackupConfigValues) {
    switch (backupConfigValues.backupViewMode) {
      case BackupViewMode.BackupViewer:
        if(backupConfigValues?.backupId)
          return <ControllerBackupViewer params={{ id: backupConfigValues?.backupId }} hideBackButton />
        else 
          return <div className='device-comparison-backup-view'>{t('ui_controllermanager_servicenow_devicereplacement_compare_backup_none')}</div>
      case BackupViewMode.NewBackup:
        return <div className='device-comparison-backup-view'>{t('ui_controllermanager_servicenow_devicereplacement_compare_backup_takenew')}</div>
      case BackupViewMode.ExistingBackup:
        return <div className='device-comparison-backup-view'>{t('ui_controllermanager_servicenow_devicereplacement_compare_backup_useexisting', { backupDateTime: formatDate(backupConfigValues.backupAt, true, '.', backupConfigValues.timeZoneId) })}</div>
      default:
        return <></>
    }
  }


  return (
    <Card className='controller-config-container'>
      <CardHeader>{title}</CardHeader>
      <SimpleTable className='controller-config-section-table'>
        <SimpleTableRow className='controller-config-section-row'>
          <SimpleTable className='controller-config-value-table'>
            <SimpleTableRow className='table-header'>
              <SimpleTableCell>Key</SimpleTableCell>
              <SimpleTableCell>Value</SimpleTableCell>
              <SimpleTableCell>New Value</SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow className='value-row value-row-even'>
              <SimpleTableCell>Serial</SimpleTableCell>
              <SimpleTableCell><a href={`/controllerManager/${controllerConfigValues.controllerId}`} target='_blank' rel='noreferrer'>{controllerConfigValues?.serial.value}</a>{}</SimpleTableCell>
              <SimpleTableCell>{controllerConfigValues?.serial?.newValue}</SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow className='value-row'>
              <SimpleTableCell>Type</SimpleTableCell>
              <SimpleTableCell>{controllerConfigValues?.type.value}</SimpleTableCell>
              <SimpleTableCell>{controllerConfigValues?.type?.newValue}</SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow className='value-row value-row-even'>
              <SimpleTableCell>Name</SimpleTableCell>
              <SimpleTableCell>{controllerConfigValues?.name.value}</SimpleTableCell>
              <SimpleTableCell>{controllerConfigValues?.name?.newValue}</SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow className='value-row'>
              <SimpleTableCell>Site</SimpleTableCell>
              <SimpleTableCell><a href={`/sitedetails/${controllerConfigValues?.siteId.value}`} target='_blank' rel='noreferrer'>{controllerConfigValues?.siteName.value}</a></SimpleTableCell>
              <SimpleTableCell>{controllerConfigValues?.siteId.newValue && <a href={`/sitedetails/${controllerConfigValues?.siteId.newValue}`} target='_blank' rel='noreferrer'>{controllerConfigValues?.siteName.newValue}</a>}</SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow className='value-row value-row-even'>
              <SimpleTableCell>IP Address</SimpleTableCell>
              <SimpleTableCell>{controllerConfigValues?.ipAddress.value}</SimpleTableCell>
              <SimpleTableCell>{controllerConfigValues?.ipAddress?.newValue}</SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow className='value-row'>
              <SimpleTableCell>ICC</SimpleTableCell>
              <SimpleTableCell>{controllerConfigValues?.icc.value}</SimpleTableCell>
              <SimpleTableCell>{controllerConfigValues?.icc?.newValue}</SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow className='value-row value-row-even'>
              <SimpleTableCell>MSISDN</SimpleTableCell>
              <SimpleTableCell>{controllerConfigValues?.msisdn.value}</SimpleTableCell>
              <SimpleTableCell>{controllerConfigValues?.msisdn?.newValue}</SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow className='value-row'>
              <SimpleTableCell>Script</SimpleTableCell>
              <SimpleTableCell>{controllerConfigValues?.script.value}</SimpleTableCell>
              <SimpleTableCell>{controllerConfigValues?.script?.newValue}</SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow className='value-row value-row-even'>
              <SimpleTableCell>Last contact</SimpleTableCell>
              <SimpleTableCell>{controllerConfigValues?.lastContact.value}</SimpleTableCell>
              <SimpleTableCell>{controllerConfigValues?.lastContact?.newValue}</SimpleTableCell>
            </SimpleTableRow>
          </SimpleTable>
        </SimpleTableRow>
        <SimpleTableRow className='controller-config-section-row'>{mapMarker && <Map markers={[mapMarker]} name='Controller' clustered className="device-comparison-map" />}</SimpleTableRow>
        <SimpleTableRow className='controller-config-section-row'>{backupConfigValues && setBackupViewMode(backupConfigValues)}</SimpleTableRow>
      </SimpleTable>
    </Card>
  )
}

export default ControllerConfig;