import React, { FC, memo } from 'react';
import { GridWrapper, IGridWrapperProps } from '../grid-wrapper';
import { CreateSiteFromTicket } from '../../../create-new-site-from-ticket';
import { DeviceReplacementFromTicket } from '../../../device-replacement-from-ticket';
import './controller-manager-grid-wrapper.css';
import SlideOutSidePanel from '$components/sidepanel/slideout-sidepanel';
import useControllerManagerContext, { ActiveSlideoutComponent } from '../../contexts/controller-manager-context';
import { CreateNewController } from '../../../create-new-controller';

interface IControllerManagerGridWrapperProps extends IGridWrapperProps {
}

const ControllerManagerGridWrapperComponent: FC<IControllerManagerGridWrapperProps> = ({
  ...rest
}) => {

  const ctx = useControllerManagerContext();

  function SetSlideoutContent(activeComponent: ActiveSlideoutComponent) {
    switch (activeComponent) {
      case ActiveSlideoutComponent.SwapController:
        return <DeviceReplacementFromTicket />
      case ActiveSlideoutComponent.CreateSite:
        return <CreateSiteFromTicket />
      case ActiveSlideoutComponent.CreateController:
        return <CreateNewController />
      default: 
        return ActiveSlideoutComponent.None

    }
  }

  //ColumnEditor is embedded in the grid component
  function shouldShowSlideout(activeComponent: ActiveSlideoutComponent) {
    switch (activeComponent) {
      case ActiveSlideoutComponent.None:
      case ActiveSlideoutComponent.ColumnEditor:
        return false;
      default:
        return true;
    }
  }

  return (
    <div className="cmgw">
      <div className="cmgw-grid-container">
        <GridWrapper {...rest} />
      </div>
      <SlideOutSidePanel open={shouldShowSlideout(ctx.activeSlideoutComponent)} width={450}>
        {SetSlideoutContent(ctx.activeSlideoutComponent)}
      </SlideOutSidePanel>
    </div>
  );
};

export const ControllerManagerGridWrapper = memo(ControllerManagerGridWrapperComponent);