import React, { FC, memo, useState } from 'react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { ControllerIdsLoaderReturn } from '../../controllermanager';
import { useIsMobile } from '$lib/hooks/isMobile';
import { Button } from '@mui/material';
import { CardWithHeader } from '$components/cards/mui-card';
import { SimpleControllerGrid } from '../simple-controller-grid';
import Sitelist from '$pages/sitelistpage-react/modules/sitelist/sitelist.react';
import { mutate } from '$lib/hooks/fetch-utillities';
import { AttachToSiteDocument } from '$typings/graphql-codegen';
import { ensureNumber } from '$lib/numberHelpers';
import { isEmpty, isNone } from '$lib/helpers';
import Icon from '$components/icons/icon/icon.react';
import { HorizontalSplitter } from '$components/horizontal-splitter';
import MobileSitelist from '$pages/sitelistpage-react/modules/mobile-sitelist/mobile-sitelist';
import classNames from 'classnames';

interface IAttachToSiteProps {}


const AttachToSiteComponent: FC<
  IAttachToSiteProps
> = ({}: IAttachToSiteProps) => {
  const [t] = useCaseInsensitiveTranslation();
  const [error, setError] = useState(false);
  const [activeQuery, setActiveQuery] = useState(false);
  const { controllerIds } = useLoaderData() as ControllerIdsLoaderReturn;
  const isMobile = useIsMobile();
  const [selectedSiteId, setSelectedSiteId] = useState<number | undefined>(
    undefined
  );
  const [showControllers, setShowControllers] = useState(true);
  const navigate = useNavigate();

  const attach = () => {
    if (isNone(selectedSiteId) || isEmpty(controllerIds)) {
      return;
    }

    setError(false);
    setActiveQuery(true);
    mutate(
      AttachToSiteDocument,
      { controllerIds, siteId: selectedSiteId },
      true
    )
      .then((r) => {
        if (r.attachControllers.success) {
          navigate(
            '/controllermanager/receipt/' + controllerIds.join(',') + '/attach'
          );
        } else {
          setError(true);
        }
      })
      .catch(() => setError(true))
      .finally(() => setActiveQuery(false));
  };

  return (
    <CardWithHeader
      className={classNames(!isMobile && 'mar_m')}
      headerTextVariant={isMobile ? 'body1' : undefined}
      allowHeaderTextWrap={isMobile}
      headerText={t('UI_ControllerManager_AttachController_Header_Description')}
      headerActions={
        <Button onClick={() => setShowControllers((s) => !s)}>
          {t(
            showControllers
              ? 'UI_ControllerManager_AttachController_Action_HideControllers'
              : 'UI_ControllerManager_AttachController_Action_ShowControllers'
          )}
        </Button>
      }
      footerContent={
        <>
          <Button variant={'outlined'} onClick={() => navigate(-1)}>
            {t('UI_Common_Cancel')}
          </Button>
          <Button
            variant={'contained'}
            onClick={attach}
            disabled={
              activeQuery || isNone(selectedSiteId) || isEmpty(controllerIds)
            }
            startIcon={
              activeQuery ? <Icon name={'fa-spinner fa-pulse'} /> : undefined
            }
          >
            {t(error ? 'ui_common_tryagain' : 'UI_Common_Attach')}
          </Button>
        </>
      }
    >
      <HorizontalSplitter
        initialSizePercentage={"15%"}
        minPxHeightFirst={90}
        firstComponent={
          showControllers && ( 
            <div className="fill-width">
              <SimpleControllerGrid controllerIds={controllerIds} />
            </div>
          )
        }
        minPxHeightSecond={300}
        secondComponent={!isMobile ? 
          <Sitelist
            selectSingleRow={true}
            select={'site'}
            selectedRowsChanged={(selectedIds: string[]) => {
              if (selectedIds.length !== 1) {
                setSelectedSiteId(undefined);
                return;
              }
              const asNumber = ensureNumber(selectedIds[0]);
              setSelectedSiteId(asNumber);
            }}
            noPadding
            rememberScroll={false}
            showBackToTopButton={false}
            hideActionBar
            disableMap
          /> : <MobileSitelist selectConfig={{ setSelectedSiteId, selectedSiteId }} />
        }
      />
    </CardWithHeader>
  );
};

export const AttachToSite = memo(AttachToSiteComponent);
