import React, { FC, memo, useState } from 'react';
import { useQuery } from '$lib/hooks/fetch-utillities';
import './shipto-lookup.css'
import {
    GetShipToWithAddressBySoldToQueryDocument
} from '$typings/graphql-codegen';
import ModalCard from '$components/modals/modal-card/modal-card.react';
import Card from "$components/cards/card/card.react";
import CardContent from "$components/cards/card-content/card-content.react";
import CardHeader from '$components/cards/card-header/card-header.react';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import Button from "$components/buttons/button.react"
import {ShipToLookupItem} from './modules/shipto-lookup-item/shipto-lookup-item'
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react'
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react'
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react'
import LoadingBars from '$components/loading-bars/loading-bars.react'
import { WarningText } from '$components/texts/warning-text/warning-text.react';
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";

type IShipToLookupProps = {
    siteName: string
    soldTo: string
    onSelect: (value: string | undefined) => void
    onCancel: () => void
}

export type Address = {
    street: string
    postalCode: string
    city: string
    country: string
}

const ShipToLookupComponent: FC<IShipToLookupProps> = ({
    siteName,
    soldTo,
    onSelect,
    onCancel
}) => {

    const variables = { soldTo: soldTo };
    const [t] = useCaseInsensitiveTranslation()
    const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined)
    const { data, loading } = useQuery(GetShipToWithAddressBySoldToQueryDocument, variables);

    return (
        <>
            <ModalCard>
                <CardHeader className='ship-to-header'>
                    <div>
                    <p style={{fontWeight:"bold"}}>Select chosen ShipTo</p>
                <div>
                    <SimpleTable>
                        <SimpleTableRow className="ship-to-header-row">
                            <SimpleTableCell>
                                {siteName}
                            </SimpleTableCell>
                            <SimpleTableCell>
                                {soldTo}
                            </SimpleTableCell>
                        </SimpleTableRow>
                    </SimpleTable>
                    </div>
                    </div>

                </CardHeader>
               
                <CardContent style={{ overflow: "auto" }}>

                    {data && data?.getShipToWithAddress?.length > 0 ? (
                        data?.getShipToWithAddress.map((item) => {
                            const address: Address = {
                                street: item.street ?? '',
                                postalCode: item.postalCode ?? '',
                                city: item.city ?? '',
                                country: item.country ?? ''
                            };

                            return <ShipToLookupItem key={item.shipTo} onClick={() => setSelectedItem(item.shipTo)} shipTo={item.shipTo} soldTo={soldTo} address={address} isSelected={selectedItem === item.shipTo} />;
                        })
                    ) : (
                        <>{!loading && !data?.getShipToWithAddress?.length &&
                            <Card>
                                <CardContent>
                                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                                        <WarningText>{t('ui_controllermanager_servicenow_createsite_no_shiptos_found')}</WarningText>
                                    </div>
                                </CardContent>
                            </Card>}
                        </>

                    )}
                    {loading && <LoadingBars center />}
                </CardContent>
                <CardFooter className='ship-to-footer'>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                        <Button className='ship-to-cancel-button' onClick={() => onCancel()}>Cancel</Button>
                        <Button onClick={() => onSelect(selectedItem)} disabled={!selectedItem}>Select</Button>
                    </div>
                </CardFooter>

            </ModalCard>

        </>
    );
}
export const ShipToLookup = memo(ShipToLookupComponent);


