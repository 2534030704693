import React, { FC} from 'react';
import './slideout-sidepanel.css'

interface ISlideOutSidePanelProps {
    open: boolean
    width: string | number
    children: React.ReactNode
}


const SlideOutSidePanel: FC<ISlideOutSidePanelProps> = ({
    width,
    open,
    children
}) => {

    const panelStyle = {
        width: width
    };

    return (
        <>
            {open && (
                <div className='slideout-panel' style={panelStyle}>
                    {children}
                </div>
            )}
        </>
    );
};

export default SlideOutSidePanel;
