import React, { FC, memo, useState, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import './device-replacement-from-ticket.css'
import FormElement from '$components/forms/form-element/form-element.react';
import Card from "$components/cards/card/card.react";
import CardContent from "$components/cards/card-content/card-content.react";
import CardHeader from '$components/cards/card-header/card-header.react';
import CardFooter from '$components/cards/card-footer/card-footer.react';
import TextField from "$components/textbox/text-field.react";
import { mutate } from '$lib/hooks/fetch-utillities';
import {
  GetServiceNowDeviceReplacementTicketDocument,
  CheckDeviceReplacmentAllowedPreserveConfigDocument,
  CheckDeviceReplacementAllowedTerminateSimDocument,
  GetControllerAndTypeBySerialDocument,
  PreserveConfigModeEnum
} from '$typings/graphql-codegen';
import Button from "../../../../../components/buttons/button.react"
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import ErrorText from '$components/texts/error-text/error-text.react';
import { WarningText } from '$components/texts/warning-text/warning-text.react';
import Checkbox from '$components/checkbox/checkbox.react';
import RadioButtonGroup from '$components/radio-buttons/radio-button-group'
import { formatDate } from '../../../../../utility'
import { useNavigate } from 'react-router-dom';
import { ControllerCompareValues } from './modules/device-replacement-comparison/ControllerCompareValues';
import useControllerManagerContext, { ActiveSlideoutComponent } from '../controller-list/contexts/controller-manager-context';
import Icon from '$components/icons/icon/icon.react';

interface IDeviceReplacementFromTicketFormValues {
  ticketNumber: string;
  ticketUrl: LinkData;
  onBehalfOf: string;
  oldDeviceType: string;
  oldDeviceName: LinkData;
  oldDeviceSerial: string;
  newDeviceType: string
  newDeviceName: LinkData;
  newDeviceSerial: string;
  shouldTerminateOldSim: boolean;
  shouldPreserveConfig: boolean;
  configSource: string;
  isPlacedAndPowered: boolean;
  buid: string;
  notes: string;
}

interface DeviceReplacementFromTicketProps {

}

type ExistingController = {
  exists: boolean,
  isAssigned: boolean,
  controllerId: string | undefined,
  name: string | undefined,
  controllerType: string | undefined,
  timeZoneId: number | undefined | null
}

type LinkData = {
  url: string;
  text: string;
}

const DeviceReplacementFromTicketComponent: FC<DeviceReplacementFromTicketProps> = ({
}) => {

  const ctx = useControllerManagerContext();
  const [t] = useCaseInsensitiveTranslation()
  const navigate = useNavigate();
  const [ticketFound, setTicketFound] = useState(false);
  const [isFetchingTicket, setIsFetchingTicket] = useState(false);
  const [allowedToTerminateOldSim, setAllowedToTerminateOldSim] = useState(false);
  const [terminateOldSim, setTerminateOldSim] = useState(false);
  const [allowedToPreserveConfig, setAllowedToPreserveConfig] = useState(false);
  const [terminateOldSimFromTicket, setTerminateOldSimFromTicket] = useState(false);
  const [preserveConfig, setPreserveConfig] = useState(false);
  const [preserveConfigFromTicket, setPreserveConfigFromTicket] = useState(false)
  const [allowedToTakeNewBackup, setAllowedToTakeNewBackup] = useState(false);
  const [allowedToUseExistingBackup, setAllowedToUseExistingBackup] = useState(false);
  const [lastBackup, setLastBackup] = useState<Date | undefined>(undefined);
  const [lastBackupId, setLastBackupId] = useState<number | undefined>(undefined)
  const [controllerTimeZoneId, setControllerTimeZoneId] = useState<number | undefined>(undefined)
  const [configSource, setConfigSource] = useState<PreserveConfigModeEnum>(PreserveConfigModeEnum.None)
  const [terminateSimWarnings, setTerminateSimWarnings] = useState<string[]>([])
  const [preserveConfigWarnings, setPreserveConfigWarnings] = useState<string[]>([])
  const [preserveConfigInfo, setPreserveConfigInfo] = useState<string>('')
  const {
    control,
    register,
    setValue,
    handleSubmit,
    setError,
    getValues,
    clearErrors,
    watch,
    formState: { errors }
  } = useForm<IDeviceReplacementFromTicketFormValues>({
    mode: 'onChange'
  });

  const [newDeviceType, oldDeviceType, onBehalfOf, isPlacedAndPowered] = watch(['newDeviceType', 'oldDeviceType', 'onBehalfOf', "isPlacedAndPowered"])
  function isValid() {
    return Object.keys(errors).length === 0;
  }

  const ensureString = (item: string | null | undefined): string => {
    return !!item ? item : '';
  };

  const ensureBoolean = (item: boolean | null | undefined): boolean => {
    return !!item ? item : false;
  }

  async function getServiceDeviceReplacementTicket(ticketNumber: string) {

    const variables = {
      ticketNumber: ticketNumber
    }

    setIsFetchingTicket(true);
    const ticket = await mutate(GetServiceNowDeviceReplacementTicketDocument, variables, false);
   

    if (!ticket?.getServiceNowDeviceReplacementTicket?.success) {
      setError('ticketNumber', {
        message: t(ensureString(ticket?.getServiceNowDeviceReplacementTicket?.message))
      });

      setIsFetchingTicket(false);
      setTicketFound(false);
      return;
    }

    clearErrors();
   
    setValue('ticketUrl', { text: ticketNumber, url: ensureString(ticket.getServiceNowDeviceReplacementTicket?.content?.serviceNowUrl) })
    setValue('onBehalfOf', ensureString(ticket.getServiceNowDeviceReplacementTicket?.content?.onBehalfOf))
    setValue('oldDeviceType', ensureString(ticket.getServiceNowDeviceReplacementTicket?.content?.currentDeviceType))
    setValue('oldDeviceSerial', ensureString(ticket.getServiceNowDeviceReplacementTicket?.content?.currentDeviceSerial))
    setValue('newDeviceType', ensureString(ticket.getServiceNowDeviceReplacementTicket?.content?.newDeviceType))
    setValue('newDeviceSerial', ensureString(ticket.getServiceNowDeviceReplacementTicket?.content?.newDeviceSerial))
    setValue("isPlacedAndPowered", ensureBoolean(ticket.getServiceNowDeviceReplacementTicket?.content?.isNewDevicePlacedAndPowered))
    setValue('shouldTerminateOldSim', ensureBoolean(ticket.getServiceNowDeviceReplacementTicket?.content?.shouldTerminateSim))
    setPreserveConfig(ensureBoolean(ticket.getServiceNowDeviceReplacementTicket?.content?.shouldPreserveCurrentConfig))
    setPreserveConfigFromTicket(ensureBoolean(ticket.getServiceNowDeviceReplacementTicket?.content?.shouldPreserveCurrentConfig))
    setTerminateOldSimFromTicket(ensureBoolean(ticket.getServiceNowDeviceReplacementTicket?.content?.shouldTerminateSim))
    setTerminateOldSim(ensureBoolean(ticket.getServiceNowDeviceReplacementTicket?.content?.shouldTerminateSim))
    setValue('buid', ensureString(ticket.getServiceNowDeviceReplacementTicket?.content?.buid))
    setValue('notes', ensureString(ticket.getServiceNowDeviceReplacementTicket?.content?.notes))

    await onOldControllerSerialChanged(ensureString(ticket.getServiceNowDeviceReplacementTicket?.content?.currentDeviceSerial), ensureString(ticket.getServiceNowDeviceReplacementTicket?.content?.newDeviceSerial));
    await onNewControllerSerialChanged(ensureString(ticket.getServiceNowDeviceReplacementTicket?.content?.currentDeviceSerial), ensureString(ticket.getServiceNowDeviceReplacementTicket?.content?.newDeviceSerial));

    setIsFetchingTicket(false);
    setTicketFound(true);
  }

  async function checkIfAllowedToTerminateSim(currentControllerSerial: string) {

    if (!currentControllerSerial) return;

    const variables = {
      currentControllerSerial: currentControllerSerial
    }

    const isAllowedToTerminateSimResult = await mutate(CheckDeviceReplacementAllowedTerminateSimDocument, variables, false);

    setAllowedToTerminateOldSim(isAllowedToTerminateSimResult.checkDeviceReplacementAllowedTerminateSim?.success)
    setTerminateOldSim(terminateOldSimFromTicket);

    if (isAllowedToTerminateSimResult.checkDeviceReplacementAllowedTerminateSim?.warnings)
      setTerminateSimWarnings(isAllowedToTerminateSimResult.checkDeviceReplacementAllowedTerminateSim?.warnings as string[])

  }

  async function checkIfAllowedToPreserveConfig(oldControllerSerial: string, newControllerSerial: string) {

    if (!oldControllerSerial || !newControllerSerial) return;

    const variables = {
      checkPreserveConfigRequest: {
        oldControllerSerial: oldControllerSerial,
        newControllerSerial: newControllerSerial
      }
    }

    const isAllowedToPreserveConfigResult = await mutate(CheckDeviceReplacmentAllowedPreserveConfigDocument, variables, false);


    if (isAllowedToPreserveConfigResult.checkDeviceReplacmentAllowedPreserveConfig?.success) {
      setAllowedToPreserveConfig(ensureBoolean(isAllowedToPreserveConfigResult.checkDeviceReplacmentAllowedPreserveConfig?.content?.isPreserveConfigAllowed))
      setAllowedToTakeNewBackup(ensureBoolean(isAllowedToPreserveConfigResult.checkDeviceReplacmentAllowedPreserveConfig?.content?.isTakeNewBackupAllowed))
      setAllowedToUseExistingBackup(ensureBoolean(isAllowedToPreserveConfigResult.checkDeviceReplacmentAllowedPreserveConfig?.content?.isUseExistingBackupAllowed))
      setLastBackup(isAllowedToPreserveConfigResult.checkDeviceReplacmentAllowedPreserveConfig?.content?.lastBackupAt as Date | undefined)
      setLastBackupId(isAllowedToPreserveConfigResult.checkDeviceReplacmentAllowedPreserveConfig?.content?.lastBackupId as number | undefined)
      setPreserveConfig(preserveConfigFromTicket)

    }
    else {
      setAllowedToPreserveConfig(false)
    }

    if (isAllowedToPreserveConfigResult.checkDeviceReplacmentAllowedPreserveConfig?.warnings)
      setPreserveConfigWarnings(isAllowedToPreserveConfigResult.checkDeviceReplacmentAllowedPreserveConfig?.warnings as string[])

  }


  async function checkIfControllerExists(serial: string): Promise<ExistingController> {

    let existingController: ExistingController = {
      exists: false,
      isAssigned: false,
      controllerId: undefined,
      name: undefined,
      controllerType: undefined,
      timeZoneId: undefined
    }

    if (!serial)
      return existingController;

    const result = await mutate(GetControllerAndTypeBySerialDocument, { serial, }, false)

    if (!result.controllerBySerial?.controllerId)
      return existingController;

    existingController.exists = true;
    existingController.controllerId = result.controllerBySerial?.controllerId;
    existingController.name = ensureString(result.controllerBySerial?.name);
    existingController.controllerType = result.controllerBySerial?.controllerType;
    existingController.timeZoneId = result.controllerBySerial?.timeZoneId;
    existingController.isAssigned = result.controllerBySerial?.site?.siteId ? true : false

    return existingController;
  }

  useEffect(() => {

    if (!allowedToPreserveConfig) {
      setPreserveConfig(false);
      setAllowedToPreserveConfig(false);
      setAllowedToTakeNewBackup(false);
      setAllowedToUseExistingBackup(false);
    }
  }, [allowedToPreserveConfig, preserveConfig])

  useEffect(() => {
    if ((!allowedToTakeNewBackup && configSource === PreserveConfigModeEnum.TakeNewBackup)
      || (!allowedToUseExistingBackup && configSource === PreserveConfigModeEnum.UseExistingBackup)
      || !preserveConfig)
      setConfigSource(PreserveConfigModeEnum.None)

  }, [configSource, preserveConfig, allowedToUseExistingBackup, allowedToTakeNewBackup])

  useEffect(() => {
    if (!allowedToTerminateOldSim) {
      setTerminateOldSim(false);
    }
    else{
      setTerminateOldSim(terminateOldSimFromTicket)
    }
  }, [allowedToTerminateOldSim, terminateOldSimFromTicket])

  useEffect(() => {
    if (preserveConfigFromTicket && !allowedToPreserveConfig)
      setPreserveConfigInfo(t('ui_controllermanager_servicenow_devicereplacement_warning_preserveconfigrequestnotpossible'))
    else {
      setPreserveConfigInfo('')
    }
  }, [allowedToPreserveConfig, preserveConfigFromTicket])

  async function onOldControllerSerialChanged(oldControllerSerial: string, newControllerSerial: string) {

    clearErrors('oldDeviceSerial');
    const oldController = await checkIfControllerExists(oldControllerSerial);
    if (!oldController.exists)
      setError('oldDeviceSerial', { message: t('ui_controllermanager_servicenow_devicereplacement_error_controllernotfound', { serial: oldControllerSerial }) })
    else if (!oldController?.isAssigned)
      setError('oldDeviceSerial', { message: t('ui_controllermanager_servicenow_devicereplacement_error_oldcontrollerisunassigned') })
    else {
      setValue('oldDeviceType', ensureString(oldController.controllerType));
      setValue('oldDeviceName', { text: ensureString(oldController.name), url: `/controllerManager/${oldController.controllerId}` })
      setControllerTimeZoneId(oldController.timeZoneId ?? undefined);
    }

    await checkIfAllowedToPreserveConfig(oldControllerSerial, newControllerSerial);
    await checkIfAllowedToTerminateSim(oldControllerSerial);
    

  }

  async function onNewControllerSerialChanged(oldControllerSerial: string, newControllerSerial: string) {

    clearErrors(['newDeviceSerial', 'shouldPreserveConfig']);
    const newController = await checkIfControllerExists(newControllerSerial);
    if (!newController.exists)
      setError('newDeviceSerial', { message: t('ui_controllermanager_servicenow_devicereplacement_error_controllernotfound', { serial: newControllerSerial }) })
    else {
      setValue('newDeviceType', ensureString(newController.controllerType));
      setValue('newDeviceName', { text: ensureString(newController.name), url: `/controllerManager/${newController.controllerId}` })
    }

    await checkIfAllowedToPreserveConfig(oldControllerSerial, newControllerSerial);

  }

  const onSubmit: SubmitHandler<IDeviceReplacementFromTicketFormValues> = async (formData) => {

    if (isValid()) {
      const controllerCompareValues: ControllerCompareValues = {
        oldSerial: formData.oldDeviceSerial,
        newSerial: formData.newDeviceSerial,
        shouldTerminateSim: terminateOldSim,
        configSource: configSource ?? PreserveConfigModeEnum.None,
        lastBackupAt: lastBackup,
        lastBackupId: lastBackupId,
        serviceNowTicket: formData.ticketNumber
      };

      navigate('replace/comparison', { state: controllerCompareValues });
    }

  };

  function debounce<T extends (...args: any[]) => any>(
    func: T,
    delay: number
  ): (...args: Parameters<T>) => Promise<ReturnType<T>> {
    let timerId: NodeJS.Timeout;
    return async function debouncedFunction(
      ...args: Parameters<T>
    ): Promise<ReturnType<T>> {
      clearTimeout(timerId);
      return new Promise((resolve) => {
        timerId = setTimeout(async () => {
          const result = await func(...args);
          resolve(result);
        }, delay);
      });
    };
  }

  const debouncedOnOldControllerSerialChanged = debounce(onOldControllerSerialChanged, 300);
  const debouncedOnNewControllerSerialChanged = debounce(onNewControllerSerialChanged, 300);

  return (
    <div style={{ position: "relative", maxHeight: "100%", height: "100%", overflowY: "auto" }}>
      <CardHeader>
        <span>{t('ui_controllermanager_servicenow_devicereplacement_header')}</span>
      </CardHeader>
      <CardContent>
        <p style={{ paddingBottom: "15px" }}>
          {t('ui_controllermanager_servicenow_devicereplacement_description')}
        </p>
        {!ticketFound ? (
          <>
            <FormElement label={t('ui_controllermanager_servicenow_createsite_ticket')}>
              <Controller
                control={control}
                name="ticketNumber"
                render={({ field }) => (
                  <div style={{ display: "flex", gap: "4px" }}>
                    <TextField
                      {...register('ticketNumber')}
                      placeholder={t('ui_controllermanager_servicenow_createsite_ticket_search_placeholder')}
                    />
                    <Button icon={isFetchingTicket ? 'fa-spinner fa-pulse' : 'fa-search'} disabled={!field?.value || isFetchingTicket} onClick={() => getServiceDeviceReplacementTicket(field?.value)}></Button>
                  </div>)} />
            </FormElement>
            {errors.ticketNumber?.message &&
              <Card>
                <CardContent>
                  <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <ErrorText>{errors.ticketNumber?.message}</ErrorText>
                  </div>
                </CardContent>
              </Card>}
          </>
        )
          :
          (
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormElement label={t('ui_controllermanager_servicenow_createsite_ticket')}>
                  <Controller
                    control={control}
                    name="ticketUrl"
                    render={({ field }) => (
                        <a href={field.value?.url} target='_blank' rel='noreferrer'>{field.value?.text}</a>
                        )} />
                </FormElement>
                <FormElement label={t('ui_controllermanager_servicenow_devicereplacement_onbehalfof')}>
                  <span>{onBehalfOf}</span>
                </FormElement>
                <FormElement label={t('ui_controllermanager_servicenow_devicereplacement_olddevice')}>
                  <Controller
                    control={control}
                    name="oldDeviceName"
                    render={({ field }) => (
                      <a href={field.value?.url} target='_blank' rel='noreferrer'>{field.value?.text}</a>
                    )} />
                </FormElement>
                <FormElement label={t('ui_controllermanager_servicenow_devicereplacement_olddevicetype')}>
                  {oldDeviceType && <span>{oldDeviceType}</span>}
                </FormElement>
                <FormElement label={t('ui_controllermanager_servicenow_devicereplacement_olddeviceserial')}>
                  <TextField
                    {...register('oldDeviceSerial')}
                    onChange={async (e) => {
                      await debouncedOnOldControllerSerialChanged(e.target?.value, getValues('newDeviceSerial'))
                    }}
                  />
                  <ErrorText>
                    {errors.oldDeviceSerial?.message}
                  </ErrorText>
                </FormElement>
                <FormElement label={t('ui_controllermanager_servicenow_devicereplacement_newdevice')}>
                  <Controller
                    control={control}
                    name="newDeviceName"
                    render={({ field }) => (
                      <a href={field.value?.url} target='_blank' rel='noreferrer'>{field.value?.text}</a>
                    )} />
                </FormElement>
                <FormElement label={t('ui_controllermanager_servicenow_devicereplacement_newdevicetype')}>
                  {newDeviceType && <span>{newDeviceType}</span>}
                  <ErrorText>
                    {errors.newDeviceType?.message}
                  </ErrorText>
                </FormElement>
                <FormElement label={t('ui_controllermanager_servicenow_devicereplacement_newdeviceserial')}>
                  <TextField
                    {...register('newDeviceSerial')}
                    onChange={async (e) => {
                      await debouncedOnNewControllerSerialChanged(getValues('oldDeviceSerial'), e.target?.value)
                    }}
                  />
                  <ErrorText>
                    {errors.newDeviceSerial?.message}
                  </ErrorText>
                </FormElement>
                <FormElement label={t('ui_controllermanager_servicenow_devicereplacement_terminateoldsim')}>
                  <Checkbox className='device-replacement-checkbox'
                    {...register('shouldTerminateOldSim')}
                    disabled={!allowedToTerminateOldSim}
                    checked={terminateOldSim}
                    onChange={() => setTerminateOldSim((b) => !b)}
                  />
                </FormElement>
                <FormElement label={t('ui_controllermanager_servicenow_devicereplacement_isdeviceplacedandpowered')}>
                  <span>{isPlacedAndPowered ? t('ui_common_yes') : t('ui_common_no')}</span>
                </FormElement>
                <FormElement label={t('ui_controllermanager_servicenow_devicereplacement_preserveoldconfig')}>
                  <Checkbox className='device-replacement-checkbox'
                    {...register('shouldPreserveConfig')}
                    disabled={!allowedToPreserveConfig || (!allowedToTakeNewBackup && !allowedToUseExistingBackup)}
                    checked={preserveConfig}
                    onChange={() => setPreserveConfig((b) => !b)}
                  />
                  <p style={{ paddingTop: "10px", color: "#df8a6f" }}>{preserveConfigInfo && preserveConfigInfo}</p>
                </FormElement>
                {preserveConfig &&
                  <FormElement label={t('ui_controllermanager_servicenow_devicereplacement_configsource')}>
                    <Controller
                      control={control}
                      name="configSource"
                      render={({ }) => (
                        <RadioButtonGroup
                          options={[
                            {
                              value: PreserveConfigModeEnum.TakeNewBackup, text: t('ui_controllermanager_servicenow_devicereplacement_configsource_newbackupoption'),
                              disabled: !allowedToTakeNewBackup
                            },
                            {
                              value: PreserveConfigModeEnum.UseExistingBackup, text: lastBackup
                                ? t('ui_controllermanager_servicenow_devicereplacement_configsource_existingbackupoption', { backupDatetime: formatDate(lastBackup, true, '.', controllerTimeZoneId) })
                                : t('ui_controllermanager_servicenow_devicereplacement_configsource_nobackupavaliable'),
                              disabled: !allowedToUseExistingBackup || !lastBackup
                            }
                          ]}
                          selectedOption={configSource}
                          onChange={(option) => setConfigSource(option)}
                        />
                      )} />

                  </FormElement>
                }
                <FormElement label={t('ui_controllermanager_servicenow_devicereplacement_buid')}>
                  <Controller
                    control={control}
                    name="buid"
                    render={({ field }) => (
                      <span>{field.value}</span>)} />
                </FormElement>
                <FormElement label={t('ui_controllermanager_servicenow_devicereplacement_notes')}>
                  <Controller
                    control={control}
                    name="notes"
                    render={({ field }) => (
                      <div style={{ display: "flex", gap: "4px" }}>
                        {field.value}
                      </div>)} />
                </FormElement>
                <>
                  {(preserveConfigWarnings?.length > 0 || terminateSimWarnings.length > 0) &&
                    <Card>
                      <CardContent>
                        <div style={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
                          <div className='device-replacement-warning-icon-wrapper'>
                            <Icon name='fa-exclamation-triangle' className='icon warning' />
                          </div>
                          <div style={{ display: "flex", gap: "8px", flexDirection: "column", justifyContent: "space-around" }}>
                            {terminateSimWarnings?.map((warn, index) => (
                              <WarningText key={index}>{t(warn)}</WarningText>
                            ))}

                            {preserveConfigWarnings?.map((warn, index) => (
                              <WarningText key={index}>{t(warn)}</WarningText>
                            ))}
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  }
                </>
                <CardFooter className='card-footer'>
                  <div style={{ display: "flex", gap: "4px", justifyContent: 'space-around' }}>
                    <Button onClick={() => ctx.setActiveSlideoutComponent(ActiveSlideoutComponent.None)} variant='secondary'>Cancel</Button>
                    {ticketFound && <Button disabled={!isValid()} type='submit'>Continue</Button>}
                  </div>
                </CardFooter>
              </form>
            </>)}
        {!ticketFound && <CardFooter className='create-new-site-card-footer'>
          <div style={{ display: "flex", gap: "4px", justifyContent: 'space-around' }}>
            <Button onClick={() => ctx.setActiveSlideoutComponent(ActiveSlideoutComponent.None)} variant='secondary'>Cancel</Button>
          </div>
        </CardFooter>}
      </CardContent>
    </div>);
}
export const DeviceReplacementFromTicket = memo(DeviceReplacementFromTicketComponent);
