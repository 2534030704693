import React, { FC, useState, useEffect } from 'react';
import ControllerConfig from './modules/controller-config/controller-config'
import { ControllerConfigValues } from './modules/controller-config/ControllerConfigValues';
import { useNavigate, useLocation } from 'react-router-dom';
import { mutate } from '$lib/hooks/fetch-utillities';
import './device-replacement-comparison.css'
import Button from "$components/buttons/button.react"
import { ControllerBySerialDocument, DeviceReplacementJobRequest, EnqueueDeviceReplacementJobDocument, PreserveConfigModeEnum } from '$typings/graphql-codegen';
import { formatDate } from '../../../../../../../utility'
import { ControllerCompareValues } from './ControllerCompareValues';
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import { BackupConfigValues, BackupViewMode } from './modules/controller-config/BackupConfigValues';
import ErrorText from '$components/texts/error-text/error-text.react';

interface DeviceReplacementConfigComparisonProps {
}

const DeviceReplacementConfigComparison: FC<DeviceReplacementConfigComparisonProps> = ({
}) => {

    const [t] = useCaseInsensitiveTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [oldControllerValues, setOldControllerValues] = useState<ControllerConfigValues>()
    const [newControllerValues, setNewControllerValues] = useState<ControllerConfigValues>()
    const [oldControllerBackupValues, setOldControllerBackupValues] = useState<BackupConfigValues>()
    const [newControllerBackupValues, setNewControllerBackupValues] = useState<BackupConfigValues>()
    const [controllerCompareValues, setControllerCompareValues] = useState<ControllerCompareValues>()
    const [topDescription, setTopDescription] = useState('');
    const [enqueueError, setEnqueueError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [serviceNowTicket, setServiceNowTicket] = useState<string>();
    const ensureString = (item: string | null | undefined): string => {
        return !!item ? item : '';
    };

    useEffect(() => {

        const controllerCompareValues = location.state as ControllerCompareValues;
        setControllerCompareValues(controllerCompareValues);
        //redirect back to controllermanager if we try to go to the page without having the context from the previous step
        if (!controllerCompareValues)
            navigate('/controllermanager')
        else {
            SetTopDescription(controllerCompareValues.configSource)
            BuildConfigComparisonValues(controllerCompareValues)
            setServiceNowTicket(controllerCompareValues.serviceNowTicket)
        }

    }, [location])

    function SetTopDescription(configSource: PreserveConfigModeEnum) {

        switch (configSource) {
            case PreserveConfigModeEnum.None:
                setTopDescription(t('ui_controllermanager_servicenow_devicereplacement_compare_donotpreserveconfig'));
                break;
            case PreserveConfigModeEnum.TakeNewBackup:
            case PreserveConfigModeEnum.UseExistingBackup:
                setTopDescription(t('ui_controllermanager_servicenow_devicereplacement_compare_preserveconfig'));
                break;
        }
    }

    function SetBackupViewMode(configSource: PreserveConfigModeEnum) {
        switch (configSource) {
            case PreserveConfigModeEnum.None:
                setOldControllerBackupValues(prevState => ({ ...prevState, backupViewMode: BackupViewMode.BackupViewer }))
                setNewControllerBackupValues(prevState => ({ ...prevState, backupViewMode: BackupViewMode.BackupViewer }))
                break;
            case PreserveConfigModeEnum.TakeNewBackup:
                setOldControllerBackupValues(prevState => ({ ...prevState, backupViewMode: BackupViewMode.BackupViewer }))
                setNewControllerBackupValues(prevState => ({ ...prevState, backupViewMode: BackupViewMode.NewBackup }))
                break;
            case PreserveConfigModeEnum.UseExistingBackup:
                setOldControllerBackupValues(prevState => ({ ...prevState, backupViewMode: BackupViewMode.BackupViewer }))
                setNewControllerBackupValues(prevState => ({ ...prevState, backupViewMode: BackupViewMode.ExistingBackup }))
                break;
        }
    }

    async function BuildConfigComparisonValues(controllerCompareValues: ControllerCompareValues) {

        const oldController = await mutate(ControllerBySerialDocument, { serial: controllerCompareValues.oldSerial }, false);

        const oldControllerValues: ControllerConfigValues = {
            controllerId: parseInt(oldController.controllerBySerial!.controllerId!),
            serial: { value: ensureString(oldController.controllerBySerial?.serial) },
            type: { value: ensureString(oldController.controllerBySerial?.controllerType) },
            name: { value: ensureString(oldController.controllerBySerial?.name) },
            siteName: { value: ensureString(oldController.controllerBySerial?.site?.name) },
            lat: oldController.controllerBySerial?.lat ?? 0,
            lng: oldController.controllerBySerial?.lng ?? 0,
            accuracy: oldController.controllerBySerial?.accuracy ?? 0,
            siteId: { value: oldController.controllerBySerial?.siteId },
            ipAddress: { value: ensureString(oldController.controllerBySerial?.simDetail?.ipAddress) },
            icc: { value: ensureString(oldController.controllerBySerial?.simDetail?.icc) },
            msisdn: { value: ensureString(oldController.controllerBySerial?.simDetail?.msisdn) },
            script: { value: ensureString(oldController.controllerBySerial?.currentRunningScriptName) },
            lastContact: { value: formatDate(ensureString(oldController.controllerBySerial?.lastContact), true, '.', oldController.controllerBySerial?.timeZoneId) },
        }

        if (controllerCompareValues.shouldTerminateSim) {
            oldControllerValues.ipAddress.newValue = 'none';
            oldControllerValues.icc.newValue = 'terminated';
            oldControllerValues.msisdn.newValue = 'none';
        }

        setOldControllerValues(oldControllerValues);

        setOldControllerBackupValues({
            backupAt: controllerCompareValues.lastBackupAt,
            backupId: controllerCompareValues.lastBackupId?.toString(),
            timeZoneId: oldController.controllerBySerial?.timeZoneId as number | undefined,
        })

        const newController = await mutate(ControllerBySerialDocument, { serial: controllerCompareValues.newSerial }, false);

        const newControllerValues: ControllerConfigValues = {
            controllerId: parseInt(newController.controllerBySerial!.controllerId!),
            serial: { value: ensureString(newController.controllerBySerial?.serial) },
            type: { value: ensureString(newController.controllerBySerial?.controllerType) },
            name: {
                value: ensureString(newController.controllerBySerial?.name),
                newValue: oldControllerValues.name.value
            },
            siteName: {
                value: ensureString(newController.controllerBySerial?.site?.name),
                newValue: oldControllerValues.siteName.value
            },
            lat: newController.controllerBySerial?.lat ?? 0.0,
            lng: newController.controllerBySerial?.lng ?? 0.0,
            accuracy: oldController.controllerBySerial?.accuracy ?? 0,
            siteId: {
                value: newController.controllerBySerial?.siteId,
                newValue: oldControllerValues.siteId.value
            },
            ipAddress: { value: ensureString(newController.controllerBySerial?.simDetail?.ipAddress) },
            icc: { value: ensureString(newController.controllerBySerial?.simDetail?.icc) },
            msisdn: { value: ensureString(newController.controllerBySerial?.simDetail?.msisdn) },
            script: {
                value: ensureString(newController.controllerBySerial?.currentRunningScriptName),
                newValue: oldControllerValues.script.value
            },
            lastContact: { value: formatDate(ensureString(newController.controllerBySerial?.lastContact), true, '.', newController.controllerBySerial?.timeZoneId) },
        }

        setNewControllerValues(newControllerValues);

        setNewControllerBackupValues({
            backupAt: controllerCompareValues.lastBackupAt,
            backupId: controllerCompareValues.lastBackupId?.toString(),
            timeZoneId: newController.controllerBySerial?.timeZoneId as number | undefined,
        })

        SetBackupViewMode(controllerCompareValues.configSource)
    }

    async function EnqueueDeviceReplacementJob(controllerCompareValues: ControllerCompareValues) {
        try {
            setIsSubmitting(true);


            const deviceReplacementRequest: DeviceReplacementJobRequest = {
                oldControllerSerial: controllerCompareValues?.oldSerial as string,
                newControllerSerial: controllerCompareValues?.newSerial as string,
                preserveConfigMode: controllerCompareValues?.configSource as PreserveConfigModeEnum,
                shouldTerminateSim: controllerCompareValues?.shouldTerminateSim as boolean,
                existingBackupAttachmentId: controllerCompareValues?.lastBackupId as number | undefined,
                serviceNowTicket: serviceNowTicket 
            }

            const enqueueDeviceReplacementResponse = await mutate(EnqueueDeviceReplacementJobDocument, { deviceReplacementRequest }, false);

            if (enqueueDeviceReplacementResponse?.enqueueDeviceReplacement?.success) {
                const jobId = enqueueDeviceReplacementResponse.enqueueDeviceReplacement.content?.deviceReplacementJobId;
                navigate(`/controllermanager/replace/status/${jobId}`);
            }
            else {
                setEnqueueError(enqueueDeviceReplacementResponse?.enqueueDeviceReplacement?.message as string)
            }

        } catch (error) {
            setEnqueueError(error)

        } finally {
            setIsSubmitting(false);
        }

    }

    return (
        <div className='device-compare-page'>
            <div className="device-compare-container-topbar">
                <div className="device-compare-container-topbar-text">{topDescription}</div>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", flex: "0 1" }}>
                    <div className="device-compare-container-topbar-buttons">
                        <Button onClick={() => navigate('/controllermanager')} variant={'secondary'}>Cancel</Button>
                        <Button icon={isSubmitting ? 'fa-spinner fa-pulse' : ''} disabled={isSubmitting} onClick={() => EnqueueDeviceReplacementJob(controllerCompareValues as ControllerCompareValues)}>Continue</Button>
                    </div>
                    <ErrorText>{enqueueError}</ErrorText>
                </div>

            </div>
            <div className='device-compare-config-container'>
                {oldControllerValues && oldControllerBackupValues && <ControllerConfig controllerConfigValues={oldControllerValues} title="Old controller" backupConfigValues={oldControllerBackupValues}></ControllerConfig>}
                {newControllerValues && newControllerBackupValues && <ControllerConfig controllerConfigValues={newControllerValues} title="New controller" backupConfigValues={newControllerBackupValues}></ControllerConfig>}
            </div>
        </div>
    );
}

export default DeviceReplacementConfigComparison;
