import Icon from '$components/icons/icon/icon.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { StateEnum } from '$typings/graphql-codegen';
import React, { FC } from 'react';
import './replacement-step.css'

export interface ReplacementStepProps {
    status: StateEnum,
    text: string
}

const ReplacementStep: FC<ReplacementStepProps> = ({
status,
text
}) => {
    const [t] = useCaseInsensitiveTranslation();

    function setStatusIcon(status: StateEnum) {
        switch (status) {
            case StateEnum.NotStarted:
                return <Icon name='fa-circle-o' className='device-replacement-status-icon' />
            case StateEnum.InProgress:
                return <Icon name='fa-circle-o-notch fa-pulse' className='device-replacement-status-icon' />
            case StateEnum.Completed:
                return <Icon name='fa-check-circle' className='device-replacement-status-icon complete-icon' />;
            case StateEnum.Failed:
                return <Icon name='fa-exclamation-circle' className='device-replacement-status-icon error-icon' />
            default:
                return <></>
        }
    }

    return (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {setStatusIcon(status)}
            <p style={{ fontSize: "20px", fontWeight: "bold" }}>{t(text)}</p>
        </div>
    )
}

export default ReplacementStep;