import React, { FC, memo, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { CardWithHeader } from '$components/cards/mui-card';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import BuidDropdown from '$components/dropdowns/buid-dropdown/buid-dropdown.react';
import CustomerDropdown from '$components/dropdowns/customer-dropdown/customer-dropdown.react';
import './create-as-new-site.css';
import { Button } from '@mui/material';
import { ControllerIdsLoaderReturn } from '../../controllermanager';
import { SimpleControllerGrid } from '../simple-controller-grid';
import { useIsMobile } from '$lib/hooks/isMobile';
import { mutate, useQuery } from '$lib/hooks/fetch-utillities';
import { BuidsQueryReactDocument, CreateSitesDocument, CustomersQueryReactDocument, ReactUserInfoQueryDocument } from '$typings/graphql-codegen';
import { ensureNumber } from '$lib/numberHelpers';
import { isEmpty, isNone } from '$lib/helpers';
import Icon from '$components/icons/icon/icon.react';
import classNames from 'classnames';

interface ICreateAsNewSiteProps {}

const CreateAsNewSiteComponent: FC<
  ICreateAsNewSiteProps
> = ({}: ICreateAsNewSiteProps) => {
  const [selectedBuid, setSelectedBuid] = useState<string | undefined>(
    undefined
  );
  const [selectedCustomer, setSelectedCustomer] = useState<string | undefined>(
    undefined
  );
  const [activeQuery, setActiveQuery] = useState(false);
  const [error, setError] = useState(false);
  const [t] = useCaseInsensitiveTranslation();
  const { controllerIds } = useLoaderData() as ControllerIdsLoaderReturn;
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { data: buids } = useQuery(BuidsQueryReactDocument);
  const { data: accessTags } = useQuery(CustomersQueryReactDocument);
  const { data: currentUser } = useQuery(ReactUserInfoQueryDocument);

  //If user has no buid access, use responsible buids    
  useEffect(() => {
    if (currentUser?.currentUser?.user !== undefined) {
      if (buids?.buids.length === 0) {
        setSelectedBuid(currentUser.currentUser?.user?.buidId?.toString());
      }
    }
  }, [buids?.buids, currentUser?.currentUser]);

  //If user has no accessTag (customer) access, use default customer
  useEffect(() => {
    if (currentUser?.currentUser?.user !== undefined) {
      if (accessTags?.customers.length === 0) {
        setSelectedCustomer(currentUser.currentUser?.user?.customerId?.toString());
      }
    }
  }, [accessTags?.customers, currentUser?.currentUser]);

  const createSites = () => {
    if (
      activeQuery ||
      isNone(selectedBuid) ||
      isNone(selectedCustomer) ||
      isEmpty(controllerIds)
    ) {
      return;
    }

    setError(false);
    setActiveQuery(true);
    mutate(
      CreateSitesDocument,
      {
        controllerIds,
        buidId: ensureNumber(selectedBuid),
        customerId: ensureNumber(selectedCustomer),
      },
      true
    )
      .then((r) => {
        if (r.createSitesFromControllers.success) {
          navigate(
            '/controllermanager/receipt/' + controllerIds.join(',') + '/create'
          );
        }
        else {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setActiveQuery(false));
  };

  return (
    <CardWithHeader
      className={classNames(!isMobile && 'mar_m')}
      headerText={t('UI_ControllerManager_NewSite_Header', {
        count: controllerIds.length,
      })}
      allowHeaderTextWrap={isMobile}
      footerContent={
        <>
          <Button variant={'outlined'} onClick={() => navigate(-1)}>{t('UI_Common_Cancel')}</Button>
          <Button
            variant={'contained'}
            onClick={createSites}
            disabled={
              activeQuery ||
              isNone(selectedBuid) ||
              isNone(selectedCustomer) ||
              isEmpty(controllerIds)
            }
            startIcon={
              activeQuery ? <Icon name={'fa-spinner fa-pulse'} /> : undefined
            }
          >
            {t(error ? 'ui_common_tryagain' : 'UI_Common_Create')}
          </Button>
        </>
      }
    >
      {isMobile && <SimpleControllerGrid controllerIds={controllerIds} />}
      <div className="action-panel">
        {buids?.buids && buids?.buids.length > 0 && (<div className="input-field">
          <span className="label">
            {t('UI_ControllerManager_NewSite_Fields_BUID')}
          </span>
          <BuidDropdown
            placeholder={'Select buid'}
            className="dropdown"
            selected={selectedBuid}
            itemClicked={({ buidId }) => {
              setSelectedBuid(buidId);
            }}
          />
        </div>)}
        {accessTags?.customers && accessTags?.customers.length > 0 && (<div className="input-field">
          <span className="label">
            {t('UI_ControllerManager_NewSite_Fields_Customer')}
          </span>
          <CustomerDropdown
            placeholder={'Select customer'}
            className="dropdown"
            selected={selectedCustomer}
            itemClicked={({ customerId }) => setSelectedCustomer(customerId)}
          />
        </div>)}
      </div>
      {!isMobile && <SimpleControllerGrid controllerIds={controllerIds} />}
    </CardWithHeader>
  );
};

export const CreateAsNewSite = memo(CreateAsNewSiteComponent);
