export enum BackupViewMode {
    BackupViewer,
    NewBackup,
    ExistingBackup
}

export type BackupConfigValues = {
    backupId?: string,
    backupAt?: Date,
    timeZoneId?: number,
    backupViewMode?: BackupViewMode
}