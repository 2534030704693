import React, { FC, memo, useState } from 'react';
import Icon from '$components/icons/icon/icon.react';
import { Button, ButtonGroup } from '@mui/material';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import './clear-filter-button.css';

interface IClearFilterButtonProps {
  onClick: () => unknown;
}

const ClearFilterButtonComponent: FC<IClearFilterButtonProps> = ({
  onClick,
}: IClearFilterButtonProps) => {
  const [t] = useCaseInsensitiveTranslation();
  const [hover, setHover] = useState(false);

  function MouseOver() {
    setHover(true);
  }

  function MouseOut() {
    setHover(false);
  }

  const hoverCssText = {
    border: '1px solid #1574b5',
    boxShadow: '0 1px 3px rgb(0 0 0 / 40%)',
  };

  const hoverCssX = {
    backgroundColor: 'rgb(168, 56, 56)',
    boxShadow: '0 1px 3px rgb(0 0 0 / 40%)',
  };

  return (
    <>
      <ButtonGroup
        className="clear-filter-button"
        onMouseOver={MouseOver}
        onMouseOut={MouseOut}
      >
        <Button
          sx={hover ? hoverCssText : undefined}
          variant="outlined"
          onClick={onClick}
        >
          {t('UI_Actions_ClearFilters')}
        </Button>
        <Button
          sx={hover ? hoverCssX : undefined}
          variant="contained"
          color="error"
          onClick={onClick}
        >
          <Icon name="fa-times" className="icon" />
        </Button>
      </ButtonGroup>
    </>
  );
};

export const ClearFilterButton = memo(ClearFilterButtonComponent);
