import React, { FC, memo, ReactNode, useState } from 'react';
import { FilterList } from '$components/grid/react-grid/filter-list';
import { ElasticSearchPage } from '$typings/graphql';
import { IFilterGroup } from '$interfaces/iFilter';
import { IReactGroupedFilterDefinition } from '$pages/sitelistpage-react/modules/sitelist/sitelist.react';

interface IControllerListFilterListProps {
  filtersChanged: (newFilters: IFilterGroup[]) => void;
  activeFilters?: IFilterGroup[];
  freeTextQuery: string[] | undefined;
  groupedFilterDefinitions: IReactGroupedFilterDefinition[];
  className?: string;
  assignedToggle: ReactNode;
  resetAllFilters: () => unknown;
  variablesHasChanged?: boolean;
}

const ControllerListFilterListComponent: FC<IControllerListFilterListProps> = ({
  filtersChanged,
  activeFilters,
  freeTextQuery,
  groupedFilterDefinitions,
  className,
  assignedToggle,
  resetAllFilters,
  variablesHasChanged
}: IControllerListFilterListProps) => {
  const [filterQuery, setFilterQuery] = useState<string>('');

  return (
    <FilterList
      setDefaultFilters={resetAllFilters}
      filtersChanged={filtersChanged}
      activeFilters={activeFilters}
      freeTextQuery={freeTextQuery}
      filterQuery={filterQuery}
      setFilterQuery={setFilterQuery}
      page={ElasticSearchPage.ControllerManager}
      groupedFilterDefinitions={groupedFilterDefinitions}
      className={className}
      slot1={assignedToggle}
      variablesHasChanged={variablesHasChanged}
    />
  );
};

export const ControllerListFilterList = memo(ControllerListFilterListComponent);
