import React, { FC, memo } from 'react';
import { useGridData } from '$components/grid/react-grid/gridhelper';
import { ControllersSortEnum, SimpleControllerGridDocument } from '$typings/graphql-codegen';
import GridCell from '$components/grid/react-grid/cell/grid-cell';
import { formatDate } from '$lib/dateHelpers';
import Grid from '$components/grid/react-grid/grid.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { useIsMobile } from '$lib/hooks/isMobile';
import { CardContent } from '@mui/material';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';

interface ISimpleControllerGridProps {
  controllerIds: number[]
}

const SimpleControllerGridComponent: FC<ISimpleControllerGridProps> = ({ controllerIds }: ISimpleControllerGridProps) => {
  const [t] = useCaseInsensitiveTranslation();

  const isMobile = useIsMobile()

  const data = useGridData(
    SimpleControllerGridDocument,
    { ids: controllerIds },
    (data) => data.controllers.length,
    (data) => data.controllers
  );

  if (isMobile) {
    return (<CardContent>
      <SimpleTable layout='fixed'>
        <SimpleTableRow>
          <SimpleTableCell>Alias</SimpleTableCell>
          <SimpleTableCell>Serial</SimpleTableCell>
        </SimpleTableRow>
        {data?.data?.map((item, index) => (
          <SimpleTableRow key={"simpletablerow_" + index}>
            <SimpleTableCell className='trunc-text'>{item?.alias}</SimpleTableCell>
            <SimpleTableCell className='trunc_text'>{item?.serial}</SimpleTableCell>
          </SimpleTableRow>
        ))}
      </SimpleTable>
    </CardContent>)
  }

  return (
    <Grid
      className="fill-width"
      items={data}
      name={'CreateAttachGrid'}
      rememberScroll={false}
      showBackToTopButton={false}
      columns={[
        {
          columnTitle: t(
            'ui_controllermanager_controllerlist_table_headers_serial'
          ),
          columnKey: 'serial',
          render: (item, width, highlightText) => (
            <GridCell highlightText={highlightText} width={width}>
              {item?.serial?.trim()}
            </GridCell>
          ),
          initialWidth: 'auto',
        },
        {
          columnTitle: t(
            'ui_controllermanager_controllerlist_table_headers_controllertype'
          ),
          columnKey: 'controllertype',
          render: (item, width, highlightText) => (
            <GridCell highlightText={highlightText} width={width}>
              {item?.controllerType?.trim()}
            </GridCell>
          ),
          initialWidth: 'auto',
        },
        {
          columnTitle: t(
            'ui_controllermanager_controllerlist_table_headers_alias'
          ),
          columnKey: 'alias',
          render: (item, width, highlightText) => (
            <GridCell highlightText={highlightText} width={width}>
              {item?.alias?.trim()}
            </GridCell>
          ),
          initialWidth: 'auto',
        },
        {
          columnTitle: t(
            'ui_controllermanager_controllerlist_table_headers_customer'
          ),
          columnKey: 'customer',
          render: (item, width, highlightText) => (
            <GridCell highlightText={highlightText} width={width}>
              {item?.site?.customer?.alias?.trim()}
            </GridCell>
          ),
          initialWidth: 'auto',
        },
        {
          columnTitle: t(
            'ui_controllermanager_controllerlist_table_headers_buid'
          ),
          columnKey: 'buid',
          render: (item, width, highlightText) => (
            <GridCell highlightText={highlightText} width={width}>
              {item?.buid?.name?.trim()}
            </GridCell>
          ),
          initialWidth: 'auto',
        },
        {
          columnTitle: t(
            'ui_controllermanager_controllerlist_table_headers_lastsampletime'
          ),
          columnKey: 'lastsampletime',
          render: (item, width, highlightText) => (
            <GridCell highlightText={highlightText} width={width}>
              {item?.lastSampleTime &&
                formatDate(item.lastSampleTime, true, '.', item.timeZoneId)}
            </GridCell>
          ),
          initialWidth: 'auto',
        },
        {
          columnTitle: t(
            'ui_controllermanager_controllerlist_table_headers_created'
          ),
          columnKey: ControllersSortEnum.Created,
          render: (item, width, highlightText) => (
            <GridCell highlightText={highlightText} width={width}>
              {item?.created && formatDate(item.created, true, '.')}
            </GridCell>
          ),
          initialWidth: 'auto',
        },
        {
          columnTitle: t(
            'ui_controllermanager_controllerlist_table_headers_createdby'
          ),
          columnKey: ControllersSortEnum.CreatedBy,
          render: (item, width, highlightText) => (
            <GridCell highlightText={highlightText} width={width}>
              {item?.createdByUser?.name?.trim()}
            </GridCell>
          ),
          initialWidth: 'auto',
        },
      ]}
      sortedColumnKey={'alias'}
    />
  );
};

export const SimpleControllerGrid = memo(SimpleControllerGridComponent);
