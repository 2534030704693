import { useQuery } from '$lib/hooks/fetch-utillities';
import {
  ProductsQueryReactDocument,
  ProductsQueryReactQuery,
} from '$typings/graphql-codegen';
import React, { FC, ReactElement } from 'react';
import Dropdown from '../dropdown/dropdown.react';

type ProductDropdownItem = ProductsQueryReactQuery['products'][0];

interface IProductDropdownProps {
    selected?: ProductDropdownItem | string;
    valuePath?: string;
    displayPath?: string;
    itemClicked?: (params: { productId: string | undefined; name?: string }) => void;
    placeholder?: string;
    topElement?: string;
    error?: boolean;
    replaceTopElement?: (query: string) => ReactElement;
    className?: string;
    canSelectBlankValue?: boolean;
  }

  const ProductDropdown: FC<IProductDropdownProps> = ({
    selected,
    itemClicked,
    placeholder,
    topElement,
    error,
    replaceTopElement,
    className,
    displayPath,
    valuePath,
    canSelectBlankValue,
  }) => {
    const { data, loading } = useQuery(ProductsQueryReactDocument);
  
    return (
      <Dropdown<ProductDropdownItem>
        items={data?.products}
        className={className}
        displayPath={displayPath ?? 'name'}
        valuePath={valuePath ?? 'productId'}
        selected={selected}
        loading={loading}
        itemClicked={params => itemClicked && itemClicked({ productId: params?.value?.toString(), name: params?.text.toString()})}
        placeholder={placeholder}
        topElement={topElement}
        error={error}
        replaceTopElement={replaceTopElement}
        canSelectBlankValue={canSelectBlankValue}
      />
    );
  };
  

  export default ProductDropdown;