import React, { useEffect, useState, FC, memo } from 'react';
import './controllerlistreact.css';
import Card from '$components/cards/card/card.react';
import {
  getFreeTextQueryRowCount,
  useGridData,
  UseGridDataReturnType
} from '$components/grid/react-grid/gridhelper';
import {
  ColumnKeyAndTitle,
  ControllerManagerListDocument,
  ControllerManagerListQueryVariables,
  ControllersSortEnum,
  GenerateControllerlistDownloadExcelDocument,
  GenerateControllerlistDownloadExcelQuery,
  GenerateControllerlistDownloadExcelQueryVariables,
  SortDirection,
} from '$typings/graphql-codegen';

import Icon from '$components/icons/icon/icon.react';
import CardContent from '$components/cards/card-content/card-content.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import TextboxMultiline from '$components/textbox-multiline/textbox-multiline.react';
import DataFreshness from '$pages/common/data-freshness/data-freshness.react';
import {
  revalidateAllActiveQueries,
  runSingleQuery,
} from '$pages/common/GraphQLFetcher';
import { usePersistedParsedState } from '$lib/hooks/usePersistedState';
import { useDebounce } from '$lib/hooks/debounce';
import { isSomething } from '$lib/helpers';
import { assureArray } from '$lib/arrayHelpers';
import { Router } from 'aurelia-router';
import { saveBlob } from '$lib/fileHelpers';
import { useControllerlistColumns } from './hooks/useControllerlistColumns';
import { useIsMobile } from '$lib/hooks/isMobile';
import { IReactGridColumn } from '$components/grid/react-grid/grid.react';
import { ElasticSearchPage } from '$typings/graphql';
import { variablesAreEqual } from '$components/grid/react-grid/compare-variables';
import { MobileControllerlist } from './modules/mobile-controllerlist';
import { ControllerManagerListRow } from './utils/controller-manager-list-row';
import { ControllerService } from '$services/controllerService';
import { Button, ButtonGroup } from '@mui/material';
import { ClearFilterButton } from '$components/buttons/clear-filter-button';
import { IFilterGroup } from '$interfaces/iFilter';
import { getUserFeatures, getSession } from '../../../../../config/sessionService';
import { useNavigate } from 'react-router-dom';
import { ControllerDetailsURLParams } from '$pages/controllermanager/controllermanager-react/controllermanagerwrapper';
import { ControllerManagerGridWrapper } from './modules/controller-manager-grid-wrapper';
import { ActiveSlideoutComponent, ControllerManagerContext } from './contexts/controller-manager-context';
import useControllerManagerContextValues from './hooks/useControllerManagerContextValues';

export enum ControllersAssignedStatus {
  Assigned = 'Assigned',
  Unassigned = 'Unassigned',
}

const defaultFilters: IFilterGroup[] = [
  {
    exclude: false,
    field: 'assigned',
    type: 'boolean',
    filters: [{ value: false }],
  },
];

const defaultVariables: ControllerManagerListQueryVariables = {
  sortDirection: SortDirection.Asc,
  sortProperty: ControllersSortEnum.Alias,
  filters: JSON.stringify(defaultFilters),
  freeTextQuery: [''],
  offset: 0,
  first: 100,
};

const getInitialAssignedQuery = (
  variables: ControllerManagerListQueryVariables
) => {
  if (!variables.filters) {
    return undefined;
  }

  const assignedFilter: IFilterGroup = JSON.parse(variables.filters)
    .find((f: IFilterGroup) => f.field === 'assigned');
  if (!assignedFilter) {
    return undefined;
  }
  return assignedFilter.exclude
    ? ControllersAssignedStatus.Unassigned
    : ControllersAssignedStatus.Assigned;
};

const getInitialFreeTextQueryValue = (
  variables: ControllerManagerListQueryVariables
) => {
  if (typeof variables.freeTextQuery === 'string') {
    return variables.freeTextQuery;
  }
  return variables.freeTextQuery?.join('\n');
};

interface IControllerListProps {
  // workaround until we switch to react-router
  aureliaRouter: Router;
  aureliaControllerService: ControllerService;
  params?: ControllerDetailsURLParams;
}

export const ControllerListComponent: FC<IControllerListProps> = ({
  aureliaRouter,
  aureliaControllerService,
  params,
}) => {

  const ctxValues = useControllerManagerContextValues();
  const name = 'controllerlist-react';
  const navigate = useNavigate();
  const [t] = useCaseInsensitiveTranslation();
  const features = getUserFeatures();
  const session = getSession();
  const columns: IReactGridColumn<ControllerManagerListRow>[] =
    useControllerlistColumns();
  const isMobile = useIsMobile();

  const [variables, setVariables] =
    usePersistedParsedState<ControllerManagerListQueryVariables>(
      `${name}-variables`,
      defaultVariables
    );
  const [variablesHasChanged, setVariablesHasChanged] = useState(false);

  const [isDownloadingList, setIsDownloadingList] = useState(false);
  const [freeTextQueryValue, setFreeTextQueryValue] = useState<
    string | undefined
  >(getInitialFreeTextQueryValue(variables));
  const freeTextQueryValueDebounced = useDebounce(
    freeTextQueryValue?.trim(),
    400
  );
  const [freeTextQueryAsArray, setFreeTextQueryAsArray] = useState<
    string[] | undefined
  >(undefined);
  const [textAreaRows, setTextAreaRows] = useState(1);
  const [filters, setFilters] = useState<IFilterGroup[] | undefined>(
    variables.filters ? JSON.parse(variables.filters) : defaultFilters
  );
  const [assignedQuery, setAssignedQuery] = useState<
    ControllersAssignedStatus | undefined
  >(getInitialAssignedQuery(variables));

  const navigateToCreateNewSites = (controllerId: string | string[]) => {
    navigate("/controllermanager/create/" + assureArray(controllerId).join(','));
  };

  const navigateToAttachToSites = (controllerId: string | string[]) => {
    navigate("/controllermanager/attach/" + assureArray(controllerId).join(','));
  };

  useEffect(() => {
    const isEqual = variablesAreEqual(variables, defaultVariables);
    setVariablesHasChanged(!isEqual);
  }, [variables]);

  useEffect(() => {
    const newFilter = filters?.filter((f) => f.field !== 'assigned');
    if (isSomething(assignedQuery)) {
      newFilter?.push({
        exclude: assignedQuery === ControllersAssignedStatus.Unassigned,
        field: 'assigned',
        type: 'boolean',
        filters:
          assignedQuery === ControllersAssignedStatus.Assigned
            ? [{ value: false }]
            : [], // #6740: match defaultvariables.filters when "Assigned" button is toggled/clicked
      });
    }
    setFilters(newFilter);
  }, [assignedQuery]);

  useEffect(() => {
    const asArray = freeTextQueryValueDebounced
      ?.split('\n')
      .map((q) => q.trim());

    if (JSON.stringify(asArray) === JSON.stringify(variables.freeTextQuery)) {
      return;
    }
    
    setVariables((variables) => ({
      ...variables,
      freeTextQuery: asArray,
    }));

    setFreeTextQueryAsArray(asArray);
  }, [freeTextQueryValueDebounced]);

  useEffect(() => {
    const filterJSON = JSON.stringify(filters);
    if (filterJSON === variables.filters) return;
    
    setVariables((variables) => ({
      ...variables,
      filters: filterJSON,
    }));
  }, [filters]);

  const onFreeTextQueryChange = (newText?: string) => {
    const rows = getFreeTextQueryRowCount(newText);
    setTextAreaRows(rows < 4 ? rows : 4);
    setFreeTextQueryValue(newText);
  };

  const gridData: UseGridDataReturnType<ControllerManagerListRow> = useGridData(
    ControllerManagerListDocument,
    variables,
    ({ elasticSearchPages }) =>
      elasticSearchPages.controllerManager.data.totalCount,
    ({ elasticSearchPages }) => elasticSearchPages.controllerManager.data.edges
  );

  function loadNewData() {
    revalidateAllActiveQueries();
  }

  const downloadExcel = async () => {
    setIsDownloadingList(true);
    const skippedKeys = ['ping', 'controllSuite'];

    const excelDownloadVariables: GenerateControllerlistDownloadExcelQueryVariables =
      {
        ...variables,
        columnKeyAndTitle: columns
          .filter((a) => !skippedKeys.includes(a.columnKey))
          .map<ColumnKeyAndTitle>((column) => ({
            key: column.columnKey,
            title: t(column.columnTitle),
          })),
      };

    try {
      const { promise } = runSingleQuery<
        GenerateControllerlistDownloadExcelQuery,
        GenerateControllerlistDownloadExcelQueryVariables
      >(GenerateControllerlistDownloadExcelDocument, excelDownloadVariables);
      const excelSheet = await promise;

      const file = await fetch(
        excelSheet.elasticSearchPages.controllerManager.excelSheet
      );
      const blob = await file.blob();
      saveBlob(blob, 'Controllers.xlsx');
    } catch (error) {
      throw new Error('Could not download excel');
    } finally {
      setIsDownloadingList(false);
    }
  };

  const clearFilters = () => {
    setVariables(defaultVariables);
    setFilters(defaultFilters);
    setFreeTextQueryValue(undefined);
    setAssignedQuery(ControllersAssignedStatus.Assigned);
    setTextAreaRows(1);
  };

  const setUrlParamFilter = () => {
    if (!params) return;

    let updatedFromParams = false;
    const { assigned, controllerType, serial, buid } = params;

    if (assigned === 'true') {
      setAssignedQuery(ControllersAssignedStatus.Assigned);
      updatedFromParams = true
    }

    const newFiler = [...defaultFilters];

    if (serial) {
      setFreeTextQueryValue(serial);
      updatedFromParams = true
    }

    if (controllerType) {
      const controllerTypeFilter = {
        exclude: false,
        field: 'controllertype',
        type: 'ref',
        filters: [{ value: controllerType }],
      };
      newFiler.push(controllerTypeFilter);
      updatedFromParams = true;
    }

    if (buid) {
      const buidFilter = {
        exclude: false,
        field: 'sitebuid',
        type: 'ref',
        filters: [{ value: buid }],
      };
      newFiler.push(buidFilter);
      updatedFromParams = true;
    }
    
    if (updatedFromParams) {
      setVariables({ ...defaultVariables, filters: JSON.stringify(newFiler) });
      setFilters(newFiler);
      setFreeTextQueryValue(undefined);
      setAssignedQuery(ControllersAssignedStatus.Assigned);
      setTextAreaRows(1);
    }
  };

  useEffect(setUrlParamFilter, [params]);

  const controllerCount = gridData.data?.length ?? 0;
  const controllerCountText = t('ui_count_controller', { count: controllerCount });
  const dataFreshnessPrefixText = `${controllerCountText} `;

  if (isMobile) {
    return (
      <MobileControllerlist
        gridData={gridData}
        columns={columns}
        freeTextQueryValue={freeTextQueryValue}
        freeTextQueryAsArray={freeTextQueryAsArray}
        onFreeTextQueryChange={onFreeTextQueryChange}
        aureliaRouter={aureliaRouter}
        loadNewData={loadNewData}
        dataFreshnessPrefixText={dataFreshnessPrefixText}
        filters={filters}
        setFilters={setFilters}
        clearFilters={clearFilters}
        variablesHasChanged={variablesHasChanged}
        assignedQuery={assignedQuery}
        setAssignedQuery={setAssignedQuery}
      />
    );
  }



  return (
    <ControllerManagerContext.Provider value={ctxValues}>
      <div className="controller-manager-controllerlist">
        <Card className="controller-list-card">
          <CardContent className="flex row jsb">
            <div className="action-bar">
              <ButtonGroup className="ai-start flex">
                <Button
                  variant={
                    assignedQuery === ControllersAssignedStatus.Unassigned
                      ? 'contained'
                      : 'outlined'
                  }
                  onClick={() =>
                    setAssignedQuery(ControllersAssignedStatus.Unassigned)
                  }
                >
                  {t(
                    'UI_ControllerManager_ControllerList_Filters_AssignedStatus_Unassigned'
                  )}
                </Button>
                <Button
                  variant={
                    assignedQuery === ControllersAssignedStatus.Assigned
                      ? 'contained'
                      : 'outlined'
                  }
                  onClick={() =>
                    setAssignedQuery(ControllersAssignedStatus.Assigned)
                  }
                >
                  {t(
                    'UI_ControllerManager_ControllerList_Filters_AssignedStatus_Assigned'
                  )}
                </Button>
                <Button
                  variant={
                    assignedQuery === undefined ? 'contained' : 'outlined'
                  }
                  onClick={() => setAssignedQuery(undefined)}
                >
                  {t(
                    'UI_ControllerManager_ControllerList_Filters_AssignedStatus_Both'
                  )}
                </Button>
              </ButtonGroup>
              <TextboxMultiline
                className="textbox-multiline"
                restrictResizeTo="no-resize"
                rows={textAreaRows}
                value={freeTextQueryValue}
                placeholder={t(
                  'UI_ControllerManager_ControllerList_Filters_Textfilter_Placeholder'
                )}
                onChange={(value) => onFreeTextQueryChange(value)}
                shiftEnterAsNewline
              />

              <div className="flex jccenter column">
                <span className="flex sitelist-results-info mar_lm nowrap">
                  <DataFreshness
                    className="totalcount"
                    pageToCheck={ElasticSearchPage.ControllerManager}
                    freshnessChanged={loadNewData}
                    prefixedText={dataFreshnessPrefixText}
                  />
                </span>
              </div>
              {variablesHasChanged && (
                <ClearFilterButton onClick={clearFilters} />
              )}
            </div>
            <div className="right-action-buttons">
            <div className="grouped-right-action-buttons">
                {session.currentUser.isSuperUser && (
                  <Button
                    variant="outlined"
                    sx={{ marginRight: '1rem' }}
                    startIcon={<Icon name="fa-recycle" />
                    }
                    onClick={() => {
                      if (ctxValues.activeSlideoutComponent != ActiveSlideoutComponent.SwapController)
                        ctxValues.setActiveSlideoutComponent(ActiveSlideoutComponent.SwapController)
                      else
                      ctxValues.setActiveSlideoutComponent(ActiveSlideoutComponent.None)
                    }}
                  >
                    {ctxValues.activeSlideoutComponent !== ActiveSlideoutComponent.SwapController 
                    ? t('ui_controllermanager_controllerlist_actions_replace_device')
                    : t('ui_controllermanager_controllerlist_actions_close_replace_device')
                  
                  }
                  </Button>
                )}
              </div>
              <div className="grouped-right-action-buttons">
                {session.currentUser.isSuperUser && (
                  <Button
                    variant="outlined"
                    sx={{ marginRight: '1rem' }}
                    startIcon={ <Icon name="fa-industry" />
                    }
                    onClick={() => {
                      if (ctxValues.activeSlideoutComponent != ActiveSlideoutComponent.CreateSite)
                        ctxValues.setActiveSlideoutComponent(ActiveSlideoutComponent.CreateSite)
                      else
                      ctxValues.setActiveSlideoutComponent(ActiveSlideoutComponent.None)
                    }}
                  >
                    {ctxValues.activeSlideoutComponent !== ActiveSlideoutComponent.CreateSite 
                    ? t('ui_controllermanager_controllerlist_actions_create_site')
                    : t('ui_controllermanager_controllerlist_actions_close_create_site')
                  
                  }
                  </Button>
                )}
              </div>
              <div className="grouped-right-action-buttons">
                {features.createController.read && (
                  <Button
                    variant="outlined"
                    sx={{ marginRight: '1rem' }}
                    startIcon={<Icon name="fa-microchip" />
                    }
                    onClick={() => {
                      if (ctxValues.activeSlideoutComponent !== ActiveSlideoutComponent.CreateController)
                        ctxValues.setActiveSlideoutComponent(ActiveSlideoutComponent.CreateController)
                      else
                      ctxValues.setActiveSlideoutComponent(ActiveSlideoutComponent.None)
                    }}
                  >
                    {ctxValues.activeSlideoutComponent !== ActiveSlideoutComponent.CreateController 
                    ? t('ui_controllermanager_controllerlist_actions_create_controller')
                    : t('ui_controllermanager_controllerlist_actions_close_create_controller')      
                  }
                  </Button>
                )}
              </div>
              <div className="grouped-right-action-buttons">
                <ButtonGroup className="flex">
                  <Button
                    variant="outlined"
                    startIcon={<Icon name="columns" /> }
                    onClick={() => {
                      if (ctxValues.activeSlideoutComponent !== ActiveSlideoutComponent.ColumnEditor)
                        ctxValues.setActiveSlideoutComponent(ActiveSlideoutComponent.ColumnEditor)
                      else
                      ctxValues.setActiveSlideoutComponent(ActiveSlideoutComponent.None)
                    }}
                  >
                    {ctxValues.activeSlideoutComponent !== ActiveSlideoutComponent.ColumnEditor 
                    ? t('ui_sitelist_actions_editcolumns')
                    : t('ui_sitelist_actions_closecolumneditor')      
                  }

                  </Button>
                  <Button
                    variant="outlined"
                    disabled={isDownloadingList}
                    onClick={downloadExcel}
                    startIcon={
                      <Icon
                        name={
                          isDownloadingList ? 'fa-spinner fa-pulse' : 'download'
                        }
                      />
                    }
                  >
                    {t('ui_sitelist_actions_download')}
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </CardContent>
          <CardContent className="flex no-padding overflow-y-hidden grow">
            <ControllerManagerGridWrapper
             name={name}
             variables={variables}
             gridData={gridData}
             columns={columns}
             columnEditMode={ctxValues.activeSlideoutComponent === ActiveSlideoutComponent.ColumnEditor}
             activeFilters={filters}
             filtersChanged={setFilters}
             setVariables={setVariables}
             navigateToCreateNewSites={navigateToCreateNewSites}
             navigateToAttachToSites={navigateToAttachToSites}
             aureliaControllerService={aureliaControllerService}
             />
          </CardContent>
        </Card>
      </div>
      </ControllerManagerContext.Provider>
  );
};

export const ControllerList = memo(ControllerListComponent);
