import { autoinject, computedFrom } from 'aurelia-framework';
import { BaseViewModel } from '../../common';
import { Router } from 'aurelia-router';
import { getLogger } from 'aurelia-logging';
import {
  ControllerService,
  ChannelService,
  UnitService,
  ProductService
} from '../../../services';
import { rootState } from '../../../reducers';
import {
  routeNames,
  ChannelTypes,
  SecurityLevels,
  OrderProcess,
  TankTypes
} from '../../../config';
import {
  routes,
  IChannel,
  IUnit,
  IProduct,
  ITankDetails,
  IVehicle,
  IController,
  ILoadingPoint,
  IControllerManagerControllerChannelsRoute
} from '../../../interfaces';
import { Maybe } from 'tsmonad';
import { defaultMemoize } from 'reselect';
import {
  emptyArray,
  orderByPredicate,
  exitMaybe,
  getTimezoneByIdOrDefault,
  isNone
} from '../../../utility';
import { menuIsOpenForEntity } from '../../../types';
import { I18N } from 'aurelia-i18n';
import './controllerchannels.css';
import { getSession } from '../../../../src/config/sessionService';
import { runSingleQuery } from '$pages/common/GraphQLFetcher';
import gql from 'graphql-tag';
import {
  DeleteChannelInfluxData,
  DeleteChannelInfluxDataVariables
} from '$typings/graphql';

interface IControllerChannelsState {
  channels: IChannel[];
  channelAction: 'edit' | 'create' | undefined;
  channelId?: number;
  products: IProduct[];
  units: IUnit[];
  menuOpenForEntity: menuIsOpenForEntity;
  channelTypes: typeof ChannelTypes;
  tankTypes: typeof TankTypes;
  securityLevels: typeof SecurityLevels;
  orderProcesses: typeof OrderProcess;
  controllerId: number;
  controller: IController | undefined;
  timezone?: string;
}

interface IChannelDetailsTankDetails extends ITankDetails {
  vehicle?: IVehicle;
  vehicles?: IVehicle[];
  loadingPoints?: ILoadingPoint[];
  loadingPoint?: ILoadingPoint;
}

export interface IChannelDetails extends IChannel {
  unit?: IUnit;
  product?: IProduct;
  tankDetails?: IChannelDetailsTankDetails;
}

const routeIsControllerManager = (
  route: routes
): route is IControllerManagerControllerChannelsRoute =>
  route.route === routeNames.controllerManagerChannels;

const getControllerUrlFromState = (
  route: routes
): Maybe<IControllerManagerControllerChannelsRoute> =>
  routeIsControllerManager(route)
    ? Maybe.just(route)
    : route.child
    ? getControllerUrlFromState(route.child)
    : Maybe.nothing<IControllerManagerControllerChannelsRoute>();

const getControllerIdFromState = (route: routes): Maybe<number> =>
  getControllerUrlFromState(route)
    .bind(r =>
      r.params.controllerId
        ? Maybe.just(r.params.controllerId)
        : Maybe.nothing<string>()
    )
    .map(r => parseInt(r, 10));

const getActionFromState = (route: routes): Maybe<'edit' | 'create'> =>
  getControllerUrlFromState(route).bind(r =>
    r.params.action
      ? Maybe.just(r.params.action)
      : Maybe.nothing<'edit' | 'create'>()
  );

const getChannelIdFromState = (route: routes): Maybe<number> =>
  getControllerUrlFromState(route)
    .bind(r =>
      r.params.channelId
        ? Maybe.just(r.params.channelId)
        : Maybe.nothing<string>()
    )
    .map(r => parseInt(r, 10));

const getCurrentController = (
  state: rootState,
  controllerService: ControllerService
) =>
  controllerService.getController(
    getControllerIdFromState(state.router.currentRoute).valueOr(0)
  );

const mapChannelToChannelDetails = (units: IUnit[], products: IProduct[]) => (
  channel: IChannel
): IChannelDetails => ({
  ...channel,
  unit: units.find(u => u.unitId === channel.unitId),
  product: products.find(p => p.productId === channel.productId)
});
const sortProducts = defaultMemoize((products: IProduct[], i18n: I18N) =>
  orderByPredicate(products, product => i18n.tr(product.languageKey), 'asc')
);
const sortUnits = defaultMemoize((units: IUnit[], i18n: I18N) =>
  orderByPredicate(units, unit => i18n.tr(unit.translationKey), 'asc')
);

const mapState = (
  controllerService: ControllerService,
  channelService: ChannelService,
  unitService: UnitService,
  productService: ProductService,
  i18n: I18N
) => (state: rootState): IControllerChannelsState => {
  const channels = channelService.getControllerChannelsByControllerId(
    getControllerIdFromState(state.router.currentRoute).valueOr(0)
  );
  const units = unitService
    .fetchUnits()
    .map(units => sortUnits(units, i18n))
    .getEntityOrDefault(emptyArray);
  const products = productService
    .getAll()
    .map(products => sortProducts(products, i18n))
    .getEntityOrDefault(emptyArray);

  const mapChannel = mapChannelToChannelDetails(units, products);

  const controller = getCurrentController(
    state,
    controllerService
  ).getEntityOrUndefined();

  return {
    channelAction: exitMaybe(getActionFromState(state.router.currentRoute)),
    controllerId: getControllerIdFromState(state.router.currentRoute).valueOr(
      0
    ),
    channels: channels
      .map(c => c.map(mapChannel))
      .getEntityOrDefault(emptyArray),
    controller,
    products,
    units,
    channelTypes: ChannelTypes,
    securityLevels: SecurityLevels,
    tankTypes: TankTypes,
    orderProcesses: OrderProcess,
    menuOpenForEntity: state.application.menuIsOpenForEntity,
    channelId: exitMaybe(getChannelIdFromState(state.router.currentRoute)),
    timezone: controller && getTimezoneByIdOrDefault(controller.timeZoneId)
  };
};

@autoinject()
export class ControllerChannels extends BaseViewModel<
  IControllerChannelsState
> {
  constructor(
    i18n: I18N,
    public controllerService: ControllerService,
    channelService: ChannelService,
    unitService: UnitService,
    productService: ProductService,
    private router: Router
  ) {
    super(
      getLogger('ControllerChannels'),
      mapState(
        controllerService,
        channelService,
        unitService,
        productService,
        i18n
      ),
      state =>
        getControllerUrlFromState(state.router.currentRoute)
          .map(_ => true)
          .valueOr(false)
    );
  }

  isSuperUser = getSession().currentUser.isSuperUser;

  selectedChannelIds: number[] = [];
  showTimeRangeModal: boolean = false;
  confirmDeleteModal: boolean = false;
  selectedFromPeriod: Date | undefined;
  selectedToPeriod: Date | undefined;

  goToChannel({ channelId, controllerId }: IChannel) {
    this.router.navigateToRoute(routeNames.controllerManagerChannels, {
      controllerId,
      channelId,
      action: 'edit'
    });
  }

  getLink({ channelId, controllerId }: IChannel) {
    return `/controllermanager/${controllerId}/channels/edit/${channelId}`;
  }

  canNavigateToChannelFromChannelEditor = (channelId: number) => {
    return !isNone(this.state.channels.find(c => c.channelId === channelId));
  };
  navigateToChannelFromChannelEditor = (channelId: number) => {
    const channel = this.state.channels.find(c => c.channelId === channelId);
    if (isNone(channel)) return;
    this.goToChannel(channel);
  };

  navigateBackFromEdit = () =>
    this.router.navigateToRoute(routeNames.controllerManagerChannels, {
      controllerId: this.state.controllerId
    });
  channelIsDeleted = () =>
    this.router.navigateToRoute(routeNames.controllerManagerChannels, {
      controllerId: this.state.controllerId
    });

  createNewChannel = () =>
    this.router.navigateToRoute(routeNames.controllerManagerChannels, {
      controllerId: this.state.controllerId,
      action: 'create'
    });

  toggleSelectedRow = (channelId: number) => {
    if (this.selectedChannelIds.includes(channelId)) {
      this.selectedChannelIds = this.selectedChannelIds.filter(
        sc => sc !== channelId
      );
    } else {
      this.selectedChannelIds = [...this.selectedChannelIds, channelId];
    }
  };

  permanentlyDeleteData = async () => {
    const query = gql`
      mutation DeleteChannelInfluxData(
        $channelIds: [Int!]!
        $fromDate: Date!
        $toDate: Date!
      ) {
        deleteChannelInfluxData(
          channelIds: $channelIds
          fromDate: $fromDate
          toDate: $toDate
        )
      }
    `;

    const variables = {
      channelIds: this.selectedChannelIds,
      fromDate: this.selectedFromPeriod,
      toDate: this.selectedToPeriod
    };
    if (!this.selectedFromPeriod || !this.selectedToPeriod) return;
    const result = runSingleQuery<
      DeleteChannelInfluxData,
      DeleteChannelInfluxDataVariables
    >(query, variables);
    result.promise
      .then(res => {
        if (res.deleteChannelInfluxData) {
          this.selectedChannelIds = [];
          this.showTimeRangeModal = false;
          this.confirmDeleteModal = false;
        }
      })
      .catch(err => {
        throw err;
      });
  };

  @computedFrom('state.channels', 'selectedChannelIds')
  get selectedChannelNames() {
    return this.state.channels
      .filter(c => this.selectedChannelIds.includes(c.channelId))
      .map(c => c.alias ? c.alias : c.code)
      .join(', ');
  }
}
