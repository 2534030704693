import React, { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { CardWithHeader } from '$components/cards/mui-card';
import { Button, Typography } from '@mui/material';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import { ControllerForm } from './create-new-controller';
import './create-new-controller.css';
import useControllerManagerContext, { ActiveSlideoutComponent } from '../controller-list/contexts/controller-manager-context';

interface INewControllerPreviewProps {
  controllerId: string;
  controller?: ControllerForm;
}

const NewControllerPreviewComponent: FC<INewControllerPreviewProps> = ({
  controllerId,
  controller,
}: INewControllerPreviewProps) => {
  const navigate = useNavigate();
  const [t] = useCaseInsensitiveTranslation();
  const ctx = useControllerManagerContext();

  return (
    <CardWithHeader
      headerText={t('ui_controllermanager_createnewcontroller_header_success')}
      footerContent={
        <>
          <Button
            variant={'outlined'}
            onClick={() => ctx.setActiveSlideoutComponent(ActiveSlideoutComponent.None)}
          >
            {t(
              'ui_controllermanager_createnewcontroller_back_to_controllerlist'
            )}
          </Button>
          <Button
            variant={'contained'}
            color={'success'}
            onClick={() => navigate('/controllermanager/' + controllerId)}
          >
            {t('ui_controllermanager_createnewcontroller_go_to_new_controller')}
          </Button>
        </>
      }
    >
      <div className="flex column">
        <Typography ml={'1rem'} mb={'1rem'} variant={'subtitle1'}>
          {t('ui_controllermanager_createnewcontroller_created')}
        </Typography>
        <SimpleTable className="mar_bm">
          <SimpleTableRow>
            <SimpleTableCell className="simple-list-cell bg">
              {t('ui_controllermanager_controllerlist_table_headers_alias')}
            </SimpleTableCell>
            <SimpleTableCell className="simple-list-cell bg">
              {controller?.alias}
            </SimpleTableCell>
          </SimpleTableRow>
          <SimpleTableRow>
            <SimpleTableCell className="simple-list-cell">
              {t('ui_controllermanager_controllerlist_table_headers_serial')}
            </SimpleTableCell>
            <SimpleTableCell className="simple-list-cell">
              {controller?.serial}
            </SimpleTableCell>
          </SimpleTableRow>
          <SimpleTableRow>
            <SimpleTableCell className="simple-list-cell bg">
              {t(
                'ui_controllermanager_controllerlist_table_headers_controllertype'
              )}
            </SimpleTableCell>
            <SimpleTableCell className="simple-list-cell bg">
              {controller?.controllerType}
            </SimpleTableCell>
          </SimpleTableRow>
        </SimpleTable>
      </div>
    </CardWithHeader>
  );
};

export const NewControllerPreview = memo(NewControllerPreviewComponent);
