import React, { FC, memo, useEffect } from 'react';
import { Router } from 'aurelia-router';
import { ControllerService } from '$services/controllerService';
import {
  createBrowserRouter,
  RouterProvider,
  useLoaderData,
  useNavigate,
  Navigate,
  LoaderFunctionArgs,
} from 'react-router-dom';
import { ControllerList } from './modules/controller-list';
import ControllerDetails, {
  IControllerDetailsProps,
} from '$pages/common/controllers/controllerdetails-react/controller-details';
import { NotificationService } from '$services/notificationService';
import { CreateNewController } from './modules/create-new-controller';
import DeviceReplacementConfigComparison from './modules/device-replacement-from-ticket/modules/device-replacement-comparison/device-replacement-comparison'
import { getUserFeatures } from '../../../config/sessionService';
import { ensureNumber } from '$lib/numberHelpers';
import { ControllerDetailsURLParams } from '$pages/controllermanager/controllermanager-react/controllermanagerwrapper';
import { CreateAsNewSite } from './modules/create-as-new-site';
import { Receipt } from './modules/receipt/receipt';
import { AttachToSite } from './modules/attach-to-site';
import DeviceReplacementStatus from './modules/device-replacement-from-ticket/modules/device-replacement-status/device-replacement-status';

export interface IControllerManagerProps {
  aureliaRouter: Router;
  aureliaControllerService: ControllerService;
  aureliaNotificationService: NotificationService;
  params?: ControllerDetailsURLParams;
}

export type ControllerIdsLoaderReturn = { controllerIds: number[] };

const ControllerIdsLoader = (args: LoaderFunctionArgs) => {
  const controllerIds = args.params.controllerIds;
  if (controllerIds) {
    const controllerIdAsNumbers = controllerIds
      .split(',')
      .map((a) => a.trim())
      .filter((a) => a.length)
      .map(ensureNumber)
      .filter((n) => !isNaN(n));
    return { controllerIds: controllerIdAsNumbers };
  }
  throw new Error('controllerIds not provided');
};

type ControllerIdLoaderReturn = { controllerId: number };

const ControllerIdLoader = (args: LoaderFunctionArgs) => {
  const controllerId = args.params.controllerId;
  if (controllerId) {
    const controllerIdAsNumber = ensureNumber(controllerId);
    if (!isNaN(controllerIdAsNumber)) {
      return { controllerId: controllerIdAsNumber };
    }
  }
  throw new Error('controllerId not provided');
};

// extracts the controller id from the url and passes it to the details component
// reading the url param should be done inside the details component after siteDetails is under react router
const ControllerDetailsWrapper = memo(
  (
    props: Omit<
      IControllerDetailsProps,
      'controllerId' | 'closeControllerdetails'
    >
  ) => {
    const navigate = useNavigate();
    const { controllerId } = useLoaderData() as ControllerIdLoaderReturn;
    return (
      <ControllerDetails
        {...props}
        controllerId={controllerId}
        closeControllerdetails={() => navigate('/controllermanager')}
        reactRouterNavigate={navigate}
      />
    );
  }
);

const ControllerMangerComponent: FC<IControllerManagerProps> = ({
  aureliaControllerService,
  aureliaRouter,
  aureliaNotificationService,
  params
}: IControllerManagerProps) => {
  const features = getUserFeatures();

  const router = createBrowserRouter([
    {
      path: '/controllermanager/:controllerId/*',
      loader: ControllerIdLoader,
      element: features.controllerManagement.read && (
        <ControllerDetailsWrapper
          showMenubar={true}
          showAccessCard={true}
          notificationService={aureliaNotificationService}
          aureliaControllerService={aureliaControllerService}
          aureliaRouter={aureliaRouter}
        />
      ),
      errorElement: <Navigate to={'/controllermanager'} />,
    },
    {
      path: '/controllermanager',
      element: (
        <ControllerList
          aureliaControllerService={aureliaControllerService}
          aureliaRouter={aureliaRouter}
          params={params}
        />
      ),
    },
    {
      path: '/controllermanager/createcontroller',
      element: features.createController.read && <CreateNewController />,
      errorElement: <Navigate to={'/controllermanager/createcontroller'} />,
    },
    {
      path: '/controllermanager/replace/comparison',
      element: features.replaceController.write && <DeviceReplacementConfigComparison />,
      errorElement: <Navigate to={'/controllermanager'} />,
    },
    {
      path: '/controllermanager/replace/status/:jobId',
      element: features.replaceController.write && <DeviceReplacementStatus />,
      errorElement: <Navigate to={'/controllermanager'} />,
    },
    {
      path: '/controllermanager/create/:controllerIds',
      loader: ControllerIdsLoader,
      element: <CreateAsNewSite />,
      errorElement: <Navigate to={'/controllermanager'} />,
    },
    {
      path: '/controllermanager/attach/:controllerIds',
      loader: ControllerIdsLoader,
      element: <AttachToSite />,
      errorElement: <Navigate to={'/controllermanager'} />,
    },
    {
      path: '/controllermanager/receipt/:controllerIds/:type',
      loader: (args) => {
        const controllerIds = ControllerIdsLoader(args);
        const type: string | undefined = args.params.type;
        if (type === 'create' || type === 'attach') {
          return { ...controllerIds, type };
        }
        throw new Error('type not provided');
      },
      element: <Receipt />,
      errorElement: <Navigate to={'/controllermanager'} />,
    },
  ]);
  
  
  const handleNavigateToControllerManagerFromNavbar = async () => {
    // hacky solution, uses React Router BrowserRouters private method navigate.
    // only needed for bridging the gap between react and aurelia when navigating
    // inside a react component from aurelia.
    // we might need to do this elsewhere too
    await router.navigate("/controllermanager", {replace: true})
  }
  
  useEffect(() => {
    document.addEventListener('notifyReactRouter_goToControllerManager', handleNavigateToControllerManagerFromNavbar);
    return () => {
      document.removeEventListener('notifyReactRouter_goToControllerManager', handleNavigateToControllerManagerFromNavbar);
    }
  }, []);

  return <RouterProvider router={router} />;
};
export const ControllerManager = memo(ControllerMangerComponent);
