import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './device-replacement-status.css'
import {
    DeviceReplacementJobStatus,
    GetDeviceReplacementJobStatusDocument,
    GetReplacedDeviceDetailsDocument,
    GetReplacedDeviceDetailsMutation,
    PreserveConfigModeEnum,
    StateEnum
} from '$typings/graphql-codegen';
import { mutate } from '$lib/hooks/fetch-utillities';
import ErrorText from '$components/texts/error-text/error-text.react';
import ReplacementStep, { ReplacementStepProps } from './modules/replacement-step/replacement-step';

interface DeviceReplacementStatusProps { }

const DeviceReplacementStatus: FC<DeviceReplacementStatusProps> = ({

}) => {

    const { jobId } = useParams<string>();
    const [data, setData] = useState<DeviceReplacementJobStatus | undefined | null>();
    const [swappedControllerDetails, setSwappedControllerDetails] = useState<GetReplacedDeviceDetailsMutation>()
    const [loading, setLoading] = useState(false);
    const [steps, setSteps] = useState<ReplacementStepProps[]>([])
    const [error, setError] = useState('');
    const [isFirstTimeLoad, setIsFirstTimeLoad] = useState(true)
    const navigate = useNavigate();
    const [t] = useCaseInsensitiveTranslation();


    function updateReplacementStepStatus(data: DeviceReplacementJobStatus | undefined | null) {

        let replacementSteps: ReplacementStepProps[] = [];

        //Preserve config step
        if (data?.preserveConfigStatus !== StateEnum.Ignored) {

            const stepText = data?.preserveConfigMode === PreserveConfigModeEnum.TakeNewBackup
                ? t('ui_controllermanager_servicenow_devicereplacement_status_newbackup')
                : t('ui_controllermanager_servicenow_devicereplacement_status_useexistingbackup')

            replacementSteps.push({ status: data?.preserveConfigStatus as StateEnum, text: stepText })
        }

        //Device replacement step
        if (data?.deviceReplacementStatus !== StateEnum.Ignored)
            replacementSteps.push({ status: data?.deviceReplacementStatus as StateEnum, text: 'ui_controllermanager_servicenow_devicereplacement_status_devicereplacement' })

        //Restore config step
        if (data?.restoreBackupStatus !== StateEnum.Ignored)
            replacementSteps.push({ status: data?.restoreBackupStatus as StateEnum, text: 'ui_controllermanager_servicenow_devicereplacement_status_restorebackup' })

        //Terminate sim step
        if (data?.terminateSimStatus !== StateEnum.Ignored)
            replacementSteps.push({ status: data?.terminateSimStatus as StateEnum, text: 'ui_controllermanager_servicenow_devicereplacement_status_terminatesim' })

        //Show first step as in progress, so that something is spinning.
        if (isFirstTimeLoad && data?.jobStatus === StateEnum.NotStarted) {
            if (replacementSteps)
                replacementSteps[0].status = StateEnum.InProgress

            setIsFirstTimeLoad(false);
        }

        setSteps(replacementSteps);

    }


    async function fetchData() {
        setLoading(true);

        const deviceReplacementJobId = parseInt(jobId!);
        const data = await mutate(GetDeviceReplacementJobStatusDocument, { deviceReplacementJobId }, false);

        if (data.getDeviceReplacementJobStatus?.success) {
            setData(data.getDeviceReplacementJobStatus?.content);

            if (data.getDeviceReplacementJobStatus.content?.preserveConfigErrorMessage)
                setError(data.getDeviceReplacementJobStatus.content?.preserveConfigErrorMessage)

            if (data.getDeviceReplacementJobStatus.content?.deviceReplacementErrorMessage)
                setError(data.getDeviceReplacementJobStatus.content?.deviceReplacementErrorMessage)

            if (data.getDeviceReplacementJobStatus.content?.restoreBackupErrorMessage)
                setError(data.getDeviceReplacementJobStatus.content?.restoreBackupErrorMessage)

            if (data.getDeviceReplacementJobStatus.content?.terminateSimErrorMessage)
                setError(data.getDeviceReplacementJobStatus.content?.terminateSimErrorMessage)

            if (data.getDeviceReplacementJobStatus.content?.jobStatus == StateEnum.Completed) {

                const serial = data?.getDeviceReplacementJobStatus.content.newControllerSerial;
                const swappedControllerData = await mutate(GetReplacedDeviceDetailsDocument, { serial }, false);
                setSwappedControllerDetails(swappedControllerData)
            }

            updateReplacementStepStatus(data.getDeviceReplacementJobStatus?.content);

        }
        else {
            navigate('/controllerManager')
        }

        setLoading(false);
    }

    //fetch data on load
    useEffect(() => {
        if (!loading && !data) {
            fetchData();
        }
    }, []);

    // fetch data on interval to check for updated status
    useEffect(() => {
        if (data?.jobStatus === StateEnum.Completed || data?.jobStatus === StateEnum.Failed) {
            return;
        }

        const intervalId = setInterval(fetchData, 10000);
        return () => clearInterval(intervalId);
    }, [data?.jobStatus]);


    return (
        <>
            <div className='device-replacement-status-wrapper'>
                <p className='device-replacement-status-header'>{t('ui_controllermanager_servicenow_devicereplacement_status_header')}</p>
                <div className='device-replacement-status-info'>
                    <div style={{ display: "flex", gap: "10px" }}><p style={{ fontWeight: "bold" }}>{t('ui_controllermanager_servicenow_devicereplacement_status_info_controllertoreplace')}</p> <p>{data?.oldControllerSerial}</p></div>
                    <div style={{ display: "flex", gap: "10px" }}><p style={{ fontWeight: "bold" }}>{t('ui_controllermanager_servicenow_devicereplacement_status_info_replacementcontroller')}</p> <p>{data?.newControllerSerial}</p></div>
                    <div style={{ display: "flex", gap: "10px" }}><p style={{ fontWeight: "bold" }}>{t('ui_controllermanager_servicenow_devicereplacement_status_info_preserveconfigmode')}</p> <p>{data?.preserveConfigMode}</p></div>
                    <div style={{ display: "flex", gap: "10px" }}><p style={{ fontWeight: "bold" }}>{t('ui_controllermanager_servicenow_devicereplacement_status_info_terminatesim')}</p> <p>{data?.shouldTerminateSim
                        ? t('ui_controllermanager_servicenow_devicereplacement_status_info_terminatesim_yes')
                        : t('ui_controllermanager_servicenow_devicereplacement_status_info_terminatesim_no')}
                    </p>
                    </div>
                </div>
                <div className='device-replacement-status-steps'>
                    {
                        data && steps && steps.map((step, index) => {
                            return <ReplacementStep key={index} status={step.status} text={step.text} />
                        })
                    }
                </div>

                <div className='device-replacement-status-result'>
                    {error && <>
                        <ErrorText>{t(error)}</ErrorText>
                        <a href='/controllerManager' onClick={() => navigate('/controllerManager')}>Back to controller manager</a>
                    </>}

                    {swappedControllerDetails && data?.jobStatus == StateEnum.Completed &&
                        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                            <p>{t('ui_controllermanager_servicenow_devicereplacement_status_result_completetext')}</p>
                            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                                <p>{t('ui_controllermanager_servicenow_devicereplacement_status_result_linktocontroller')}</p>
                                <a onClick={() => navigate(`/controllerManager/${swappedControllerDetails.getControllerBySerial?.controllerId}`)} href={`/controllerManager/${swappedControllerDetails.getControllerBySerial?.controllerId}`}>{swappedControllerDetails.getControllerBySerial?.alias ?? swappedControllerDetails.getControllerBySerial?.name}</a>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                                <p>{t('ui_controllermanager_servicenow_devicereplacement_status_result_linktosite')}</p>
                                <a href={`/sitedetails/${swappedControllerDetails.getControllerBySerial?.site?.siteId}`}>{swappedControllerDetails.getControllerBySerial?.site?.alias ?? swappedControllerDetails.getControllerBySerial?.site?.name}</a>
                            </div>
                            <a href='/controllerManager' onClick={() => navigate('/controllerManager')}>{t('ui_controllermanager_servicenow_devicereplacement_status_result_linktocontrollermanager')}</a>
                        </div>}
                </div>
            </div>
        </>
    );
}

export default DeviceReplacementStatus;