import React from 'react';
import { IReactGridColumn } from '$components/grid/react-grid/grid.react';
import { ControllersSortEnum } from '$typings/graphql-codegen';
import GridCell from '$components/grid/react-grid/cell/grid-cell';
import StringFilter from '$pages/sitelistpage-react/modules/filters/stringfilter/stringfilter.react';
import RefFilter from '$pages/sitelistpage-react/modules/filters/reffilter/reffilter';
import { formatDate } from '$lib/dateHelpers';
import DateFilter from '$pages/sitelistpage-react/modules/filters/datefilter/datefilter.react';
import {
  controllerTypeSupportsPing,
  isPingeableIPAddress
} from '$lib/application/controllerHelpers';
import { ControllerManagerListRow } from '../utils/controller-manager-list-row';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { ElasticSearchPage } from '$typings/graphql';
import { timezones } from '$lib/timezone/timezones';
import { hasAccessToColumn } from '$lib/authorizationHelper';
import { PingCellButton } from '../modules/ping-cell';
import { SignalIcon } from '$components/icons/signal-icon/signal-icon';
import { getSession } from '../../../../../../config/sessionService';
import { ColumnAccess } from '../../../../../../config';
import BooleanFilter from '$pages/sitelistpage-react/modules/filters/booleanfilter/booleanfilter.react';
import classNames from 'classnames';

// prettier-ignore
const controllerKeyToColumnAccessMap = new Map<ControllersSortEnum, boolean>([
  [ControllersSortEnum.Signal, hasAccessToColumn(getSession(), ColumnAccess.Signal)],
  [ControllersSortEnum.AlternateSerial, hasAccessToColumn(getSession(), ColumnAccess.ControllerAlternateSerial)],
  [ControllersSortEnum.Buid, hasAccessToColumn(getSession(), ColumnAccess.Buid)],
  [ControllersSortEnum.CountryCode, hasAccessToColumn(getSession(), ColumnAccess.CountryCode)],
  [ControllersSortEnum.ControllerType, hasAccessToColumn(getSession(), ColumnAccess.DeviceType)],
  [ControllersSortEnum.Version, hasAccessToColumn(getSession(), ColumnAccess.DeviceType)],
  [ControllersSortEnum.LastBackupDate, hasAccessToColumn(getSession(), ColumnAccess.DeviceType)],
  [ControllersSortEnum.LastSampleTime, hasAccessToColumn(getSession(), ColumnAccess.LastSampleDate)],
  [ControllersSortEnum.Icc, hasAccessToColumn(getSession(), ColumnAccess.Icc)],
  [ControllersSortEnum.IpAddress, hasAccessToColumn(getSession(), ColumnAccess.IpAddress)],
  [ControllersSortEnum.Msisdn, hasAccessToColumn(getSession(), ColumnAccess.Telephone)],
  [ControllersSortEnum.Provider, hasAccessToColumn(getSession(), ColumnAccess.Telephone)],
  [ControllersSortEnum.SiteName, hasAccessToColumn(getSession(), ColumnAccess.SiteName)],
  [ControllersSortEnum.SimNotes, hasAccessToColumn(getSession(), ColumnAccess.Telephone)]
]);

export const useControllerlistColumns = () => {
  const [t] = useCaseInsensitiveTranslation();  

  const columns: IReactGridColumn<ControllerManagerListRow>[] = [
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_serial'
      ),
      columnKey: ControllersSortEnum.Serial,
      managementGroup: t('ui_controllermanager_columngroup_controller'),
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item?.serial?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_serial',
        property: 'serial',
        component: StringFilter,
      },
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_alternateserial'
      ),
      columnKey: ControllersSortEnum.AlternateSerial,
      managementGroup: t('ui_controllermanager_columngroup_controller'),
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item?.alternateSerial?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_alternateserial',
        property: 'alternateserial',
        component: StringFilter,
      },
    },
    {
      columnTitle: t('ui_controllermanager_controllerlist_table_headers_alias'),
      columnKey: ControllersSortEnum.Alias,
      managementGroup: t('ui_controllermanager_columngroup_controller'),
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item?.alias?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_alias',
        property: 'alias',
        component: StringFilter,
      },
    },
    {
      columnTitle: t('ui_controllermanager_controllerlist_table_headers_name'),
      columnKey: ControllersSortEnum.Name,
      managementGroup: t('ui_controllermanager_columngroup_controller'),
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item?.name?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_name',
        property: 'name',
        component: StringFilter,
      },
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_controllertype'
      ),
      columnKey: ControllersSortEnum.ControllerType,
      managementGroup: t('ui_controllermanager_columngroup_controller'),
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item?.controllerType?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_controllertype',
        property: 'controllertype',
        component: RefFilter,
        componentProps: { page: ElasticSearchPage.ControllerManager },
      },
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_communicationmanagerversion'
      ),
      columnKey: ControllersSortEnum.CommunicationManagerVersion,
      managementGroup: t('ui_controllermanager_columngroup_controller'),
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item?.communicationManagerVersion?.trim()}
        </GridCell>
      ),
      initialWidth: 150,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_communicationmanagerversion',
        property: 'communicationmanagerversion',
        component: RefFilter,
        componentProps: { page: ElasticSearchPage.ControllerManager },
      },
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_ipaddress'
      ),
      columnKey: ControllersSortEnum.IpAddress,
      managementGroup: t('ui_controllermanager_columngroup_sim'),
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item?.simDetails?.ipAddress?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_ipaddress',
        property: 'ipaddress',
        component: StringFilter,
      },
    },
    {
      columnTitle: t('ui_controllermanager_controllerlist_table_headers_buid'),
      columnKey: ControllersSortEnum.Buid,
      managementGroup: t('ui_controllermanager_columngroup_controller'),
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item?.buid?.name?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_buid',
        property: 'buid',
        component: RefFilter,
        componentProps: { page: ElasticSearchPage.ControllerManager },
      },
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_version'
      ),
      columnKey: ControllersSortEnum.Version,
      managementGroup: t('ui_controllermanager_columngroup_controller'),
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item?.protocolVersion?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_version',
        property: 'version',
        component: StringFilter,
      },
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_lastsampletime'
      ),
      columnKey: ControllersSortEnum.LastSampleTime,
      managementGroup: t('ui_controllermanager_columngroup_controller'),
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item.lastSampleTime &&
            formatDate(
              item.lastSampleTime,
              true,
              '.',
              timezones.find((tz) => tz.description === item.timeZone)
                ?.timezoneId
            )}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_lastsampletime',
        property: 'lastsampletime',
        component: DateFilter,
      },
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_lastbackupdate'
      ),
      columnKey: ControllersSortEnum.LastBackupDate,
      managementGroup: t('ui_controllermanager_columngroup_controller'),
      hasOwnClickHandler: true,
      render: (item, width, highlightText) => {
        const columnLink = item.site && item.backupEquipmentId ? `sitedetails/${item.site.id}/assets/view/${item.backupEquipmentId}` : undefined;
        return (
          <GridCell
            highlightText={highlightText}
            width={width}
            columnLink={columnLink}
            showPointerIfLink
            className={classNames(columnLink && 'link')}
          >
            {item.lastBackupDate &&
              formatDate(
                item.lastBackupDate,
                true,
                '.',
                timezones.find((tz) => tz.description === item.timeZone)
                  ?.timezoneId
              )}
          </GridCell>
        );
      },
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_lastbackupdate',
        property: 'lastbackupdate',
        component: DateFilter,
      },
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_created'
      ),
      columnKey: ControllersSortEnum.Created,
      managementGroup: t('ui_controllermanager_columngroup_controller'),
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item.controllerCreationDate &&
            formatDate(item.controllerCreationDate, true, '.')}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_created',
        property: 'controllerCreationDate',
        component: DateFilter,
      },
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_createdby'
      ),
      managementGroup: t('ui_controllermanager_columngroup_controller'),
      columnKey: ControllersSortEnum.CreatedBy,
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item?.createdBy?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_createdby',
        property: 'createdby',
        component: StringFilter,
      },
    },
    {
      columnTitle: t('ui_controllermanager_controllerlist_table_headers_site'),
      managementGroup: t('ui_controllermanager_columngroup_controller'),
      columnKey: ControllersSortEnum.SiteName,
      hasOwnClickHandler:true,
      render: (item, width, highlightText) => (
        <GridCell
          highlightText={highlightText}
          className="link"
          width={width}
          columnLink={item.site ? `sitedetails/${item.site.id}` : undefined}
          showPointerIfLink
        >
          {item?.site?.name?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_site',
        property: 'sitename',
        component: StringFilter,
      },
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_timezone'
      ),
      managementGroup: t('ui_controllermanager_columngroup_controller'),
      columnKey: ControllersSortEnum.TimeZone,
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item?.timeZone?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_timezone',
        property: 'timezone',
        component: StringFilter,
      },
    },
    {
      columnTitle: t('ui_controllermanager_controllerlist_table_headers_icc'),
      managementGroup: t('ui_controllermanager_columngroup_sim'),
      columnKey: ControllersSortEnum.Icc,
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item?.simDetails?.icc?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_icc',
        property: 'icc',
        component: StringFilter,
      },
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_msisdn'
      ),
      managementGroup: t('ui_controllermanager_columngroup_sim'),
      columnKey: ControllersSortEnum.Msisdn,
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item?.simDetails?.msisdn?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_msisdn',
        property: 'msisdn',
        component: StringFilter,
      },
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_provider'
      ),
      managementGroup: t('ui_controllermanager_columngroup_sim'),
      columnKey: ControllersSortEnum.Provider,
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item?.simDetails?.provider?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_provider',
        property: 'provider',
        component: StringFilter,
      },
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_simnotes'
      ),
      managementGroup: t('ui_controllermanager_columngroup_sim'),
      columnKey: ControllersSortEnum.SimNotes,
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item?.simDetails?.simnotes?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_simnotes',
        property: 'simnotes',
        component: StringFilter,
      },
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_countrycode'
      ),
      managementGroup: t('ui_controllermanager_columngroup_controller'),
      columnKey: ControllersSortEnum.CountryCode,
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item?.countryCode?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_countrycode',
        property: 'countrycode',
        component: StringFilter,
      },
    },
    // {
    //   columnTitle: t(
    //     'ui_controllermanager_controllerlist_table_headers_controllsuite'
    //   ),
    //   columnKey: 'controllSuite',
    //   managementGroup: t('ui_controllermanager_columngroup_controller'),
    //   hasOwnClickHandler: true,
    //   render: (item, width, highlightText) => {
        
    //     const features = getUserFeatures();
    //     if (
    //       token &&
    //       isSomething(token.access_token) &&
    //       features.idacs.read &&
    //       item.serial &&
    //       item.controllerType &&
    //       controllerTypeIsIda2x(item.controllerType)
    //     ) {
    //       return (
    //         <GridCell
    //           highlightText={highlightText}
    //           width={width}
    //           columnLink={IdaCSUrlToController(item.serial, token.access_token)}
    //           showPointerIfLink
    //         >
    //           <Icon name="fa-chevron-right" />
    //         </GridCell>
    //       );
    //     }
    //     return <GridCell highlightText={highlightText} width={width} />;
    //   },
    //   initialWidth: 200,
    // },
    {
      columnTitle: t('ui_controllermanager_controllerlist_table_headers_ping'),
      columnKey: 'ping',
      managementGroup: t('ui_controllermanager_columngroup_sim'),
      render: (item, width, highlightText) => (
        <GridCell
          highlightText={highlightText}
          width={width}
          className="ping-grid-cell"
        >
          {item.controllerType &&
            controllerTypeSupportsPing(item.controllerType) &&
            item.simDetails &&
            item.simDetails.ipAddress &&
            isPingeableIPAddress(item.simDetails.ipAddress) && (
              <PingCellButton controllerId={item.controllerId} />
            )}
        </GridCell>
      ),
      hasOwnClickHandler: true,
      initialWidth: 200,
      sortingDisabled: true,
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_signal'
      ),
      managementGroup: t('ui_controllermanager_columngroup_controller'),
      columnKey: ControllersSortEnum.Signal,

      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          <SignalIcon signal={item.signal}  />
        </GridCell>
      ),

      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_signal',
        property: 'signal',
        component: RefFilter,
        componentProps: { page: ElasticSearchPage.ControllerManager },
      },
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_archived'
      ),
      managementGroup: t('ui_controllermanager_columngroup_controller'),
      columnKey: ControllersSortEnum.IsArchived,

      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          <input type="checkbox" checked={item.isArchived} readOnly />
        </GridCell>
      ),

      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_archived',
        property: 'isArchived',
        component: BooleanFilter,
        componentProps: {
          values: [
            {
              value: true,
              header: 'Archived',
              sub: 'Show only archived controllers'
            },
            {
              value: false,
              header: 'Not archived',
              sub: 'Show only active controllers'
            },
            {
              value: undefined,
              default: true,
              header: 'Both',
              sub: 'Do not filter by this property'
            }
          ]
        }
      },
    },
  ];

  return columns.filter(
    (col) => controllerKeyToColumnAccessMap.get(col.columnKey) ?? true
  );
};
