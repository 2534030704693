import React, { FC, memo, useState } from 'react';
import { CardPage } from '$pages/common/card-page';
import {
  CardFormEditor,
  ICardFormEditorProps,
} from '$pages/common/form-editor';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { Controller } from 'react-hook-form';
import ControllerTypeDropdown from '$components/dropdowns/controllertype-dropdown/controllertype-dropdown';
import BuidDropdown from '$components/dropdowns/buid-dropdown/buid-dropdown.react';
import CountryCodeDropdown from '$components/dropdowns/countrycode-dropdown/countrycode-dropdown.react';
import TimezoneDropdown from '$components/dropdowns/timezone-dropdown/timezone-dropdown';
import { getSessionUser } from '../../../../../config/sessionService';
import {
  AddNewControllerDocument,
  GetControllerBySerialDocument,
} from '$typings/graphql-codegen';
import { mutate } from '$lib/hooks/fetch-utillities';
import { ensureNumber } from '$lib/numberHelpers';
import { NewControllerPreview } from './new-controller-preview';
import useControllerManagerContext, { ActiveSlideoutComponent } from '../controller-list/contexts/controller-manager-context';

type CreateTab = 'create';

export interface ControllerForm {
  serial: string;
  alias: string | undefined;
  controllerType: string;
  buidId: string | null | undefined;
  countryCode: string | undefined;
  timeZoneId: number | null | undefined;
  ipAddress: string | null | undefined;
  icc: string | null | undefined;
  msisdn: string | null | undefined;
  provider: string | null | undefined;
  simNotes: string | null | undefined;
}

const defaultFromData = {
  serial: 'VIRT.',
  controllerType: 'Virtual',
  buidId: getSessionUser().buidId.toString(),
};

interface ValidSerial {
  valid: boolean;
  reason?: string;
}

const CreateNewControllerComponent: FC = () => {
  const ctx = useControllerManagerContext();
  const [t] = useCaseInsensitiveTranslation();
  const [newControllerId, setNewControllerId] = useState<string | undefined>();
  const [postedData, setPostedData] = useState<ControllerForm | undefined>();
  const isSerialValid = async (serial: string): Promise<ValidSerial> => {
    if (serial.trim() === '')
      return { valid: false, reason: 'The serial can not be empty' };

    if (serial === defaultFromData.serial)
      return { valid: false, reason: 'The default serial can not be used' };

    return await mutate(GetControllerBySerialDocument, {
      serial,
    })
      .then((r) => {
        if (!!r.controllerBySerial?.controllerId)
          return {
            valid: false,
            reason: 'This serial is already in use by another controller',
          };
        return { valid: true };
      })
      .catch(() => {
        return {
          valid: false,
          reason: 'Something went wrong when validating the serial',
        };
      });
  };

  const formEditorProps: ICardFormEditorProps<ControllerForm> = {
    allowEdit: true,
    editing: true,
    onCancel: () => ctx.setActiveSlideoutComponent(ActiveSlideoutComponent.None),
    saveText: t('UI_ControllerManager_AddController'),
    headerText: t('UI_ControllerManager_AddController'),
    formItems: [
      {
        displayText: t('UI_Common_Controller_Label_Serial'),
        dataPropertyName: 'serial',
        validationOptions: {
          required: {
            value: true,
            message: t('ui_controllermanager_missing_serial'),
          },
        },
      },
      {
        displayText: t('UI_Common_Controller_Label_Alias'),
        dataPropertyName: 'alias',
        validationOptions: {
          required: {
            value: true,
            message: t('ui_controllermanager_missing_alias'),
          },
        },
      },
      {
        displayText: t('UI_Common_Controller_Label_ControllerType'),
        dataPropertyName: 'controllerType',
        type: 'custom',
        customEditComponent: ({ control }) => {
          return (
            <Controller
              name="controllerType"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <ControllerTypeDropdown
                  selected={field.value ?? undefined}
                  itemClicked={(item) => field.onChange(item)}
                  topElement={t<string>('ui_multiedit_formelement_unchanged')}
                  placeholder={t('ui_multiedit_formelement_unchanged')}
                  error={!!fieldState.error}
                />
              )}
            />
          );
        },
      },
      {
        displayText: t('UI_Common_Controller_Label_BUID'),
        dataPropertyName: 'buidId',
        type: 'custom',
        customEditComponent: ({ control }) => {
          return (
            <Controller
              name="buidId"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field, fieldState }) => (
                <BuidDropdown
                  selected={field.value ?? undefined}
                  itemClicked={(item) => {
                    const idAsNumber = parseInt(item.buidId ?? '');
                    if (idAsNumber && !isNaN(idAsNumber)) {
                      field.onChange(item.buidId);
                      // setPendingBuidId(idAsNumber);
                    }
                  }}
                  topElement={t<string>('ui_multiedit_formelement_unchanged')}
                  placeholder={t('ui_multiedit_formelement_unchanged')}
                  error={!!fieldState.error}
                />
              )}
            />
          );
        },
      },
      {
        displayText: t('UI_Common_Controller_Label_CountryCode'),
        dataPropertyName: 'countryCode',
        type: 'custom',
        customEditComponent: ({ control }) => (
          <Controller
            name="countryCode"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState }) => (
              <CountryCodeDropdown
                selected={field.value}
                itemClicked={(item) => field.onChange(item.countryCode)}
                error={!!fieldState.error}
              />
            )}
          />
        ),
      },
      {
        displayText: t('UI_Common_Controller_Label_Timezone'),
        dataPropertyName: 'timeZoneId',
        type: 'custom',
        customEditComponent: ({ control }) => {
          return (
            <Controller
              name="timeZoneId"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field, fieldState }) => (
                <TimezoneDropdown
                  selected={field.value?.toString()}
                  itemClicked={(item) => field.onChange(item.timezoneId)}
                  error={!!fieldState.error}
                />
              )}
            />
          );
        },
      },
      {
        displayText: t('UI_Common_Controller_SimDetails_Label_IPAddress'),
        dataPropertyName: 'ipAddress',
        // onlyShowIfPermission: columns.IpAddress,
        type: 'ipaddress',
      },
      {
        displayText: t('UI_Common_Controller_SimDetails_Label_ICC'),
        dataPropertyName: 'icc',
        // onlyShowIfPermission: columns.Icc,
        type: 'icc',
      },
      {
        displayText: t('UI_Common_Controller_SimDetails_Label_MSISDN'),
        dataPropertyName: 'msisdn',
        // valueLink: `sms:${controller.simDetail?.msisdn};?&body=ping`,
        // onlyShowIfPermission: columns.Telephone,
        type: 'msisdn',
      },
      {
        displayText: t('UI_Common_Controller_SimDetails_Label_Provider'),
        dataPropertyName: 'provider',
        // onlyShowIfPermission: columns.Telephone,
      },
      {
        displayText: t('UI_Common_Controller_SimDetails_Label_SimNotes'),
        dataPropertyName: 'simNotes',
        // onlyShowIfPermission: columns.Telephone,
      },
    ],
    data: { ...defaultFromData },
    onSubmit: (setErrorMessage) => async (data) => {
      if (!data) {
        setErrorMessage('data missing');
        return;
      }
      const serialValid = await isSerialValid(data.serial);
      if (!serialValid.valid && serialValid.reason) {
        setErrorMessage(serialValid?.reason);
        return;
      }

      if (
        !data.alias ||
        !data.buidId ||
        !data.countryCode ||
        !data.timeZoneId
      ) {
        setErrorMessage('validation failed!'); // should never happen
        return;
      }

      mutate(AddNewControllerDocument, {
        input: {
          serial: data.serial,
          alias: data.alias,
          controllerType: data.controllerType,
          buidId: ensureNumber(data.buidId),
          countryCode: data.countryCode,
          timeZoneId: ensureNumber(data.timeZoneId),
          ipAddress: data.ipAddress,
          icc: data.icc,
          msisdn: data.msisdn,
          provider: data.provider,
          simNotes: data.simNotes,
        },
      })
        .then((newController) => {
          if (newController.addController.controllerId) {
            setPostedData(data);
            setNewControllerId(newController.addController.controllerId);
          }
        })
        .catch(() => {
          setErrorMessage('Something went wrong when creating the controller.');
        });
    },
  };

  return (
    <CardPage<CreateTab>
      useMinHeight={false}
      shouldFlexGrow={true}
      showMenuBar={false}
      setSelectedMobileComponent={() => {}}
      selectedMobileComponent={'create'}
      fullHeightRows={!newControllerId}
      minRowHeight={'20rem'}
      components={[
        {
          type: 'create',
          displayText: t('UI_ControllerManager_Add_New_Controller'),
          iconName: 'fa-plus',
          component:
          
            newControllerId === undefined ? (
              <CardFormEditor<ControllerForm> {...formEditorProps} />
            ) : (
              <NewControllerPreview
                controllerId={newControllerId}
                controller={postedData}
              />
            ),
        },
      ]}
    />
  );
};

export const CreateNewController = memo(CreateNewControllerComponent);
