import React, { FC, memo } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { Button, CardContent } from '@mui/material';
import Grid from '$components/grid/react-grid/grid.react';
import GridCell from '$components/grid/react-grid/cell/grid-cell';
import { CardWithHeader } from '$components/cards/mui-card';
import { ControllerIdsLoaderReturn } from '$pages/controllermanager/controllermanager-react/controllermanager';
import { useGridData } from '$components/grid/react-grid/gridhelper';
import { ControllerManagerReceiptDocument } from '$typings/graphql-codegen';
import { useIsMobile } from '$lib/hooks/isMobile';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import classNames from 'classnames';

interface IReceiptProps { }

const ReceiptComponent: FC<IReceiptProps> = ({ }: IReceiptProps) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { controllerIds, type } =
    useLoaderData() as ControllerIdsLoaderReturn & {
      type: 'create' | 'attach';
    };
  const [t] = useCaseInsensitiveTranslation();

  const data = useGridData(
    ControllerManagerReceiptDocument,
    { controllerIds },
    (data) => data.controllers.length,
    (data) => data.controllers
  );

  return (
    <CardWithHeader
      className={classNames(!isMobile && 'mar_m')}
      headerText={t(
        type === 'attach'
          ? 'UI_ControllerManager_ControllerManagerReceipt_Attached_Message'
          : 'UI_ControllerManager_ControllerManagerReceipt_Created_Message'
      )}
      allowHeaderTextWrap={isMobile}
      footerContent={
        <div className="fill-width">
          <Button
            className={classNames(isMobile && 'fill-width')}
            variant={'outlined'}
            onClick={() => navigate('/controllermanager')}
          >
            {t('UI_ControllerManager_ControllerManagerReceipt_Back')}
          </Button>
        </div>
      }
    >
      {!isMobile ?
        <Grid
          items={data}
          name={'CreateAsNewSite'}
          rememberScroll={false}
          showBackToTopButton={false}
          columns={[
            {
              columnTitle: t(
                'ui_controllermanager_controllerlist_table_headers_serial'
              ),
              columnKey: 'serial',
              render: (item, width, highlightText) => (
                <GridCell highlightText={highlightText} width={width}>
                  {item?.serial?.trim()}
                </GridCell>
              ),
              initialWidth: 'auto',
            },
            {
              columnTitle: t(
                'ui_controllermanager_controllerlist_table_headers_alias'
              ),
              columnKey: 'alias',
              render: (item, width, highlightText) => (
                <GridCell highlightText={highlightText} width={width}>
                  {item?.alias?.trim()}
                </GridCell>
              ),
              initialWidth: 'auto',
            },
            {
              columnTitle: t(
                'ui_controllermanager_controllerlist_table_headers_site'
              ),
              columnKey: 'site',
              render: (item, width, highlightText) => (
                <GridCell
                  className="link"
                  highlightText={highlightText}
                  width={width}
                  columnLink={
                    item?.site ? `sitedetails/${item.site.siteId}` : undefined
                  }
                  showPointerIfLink
                >
                  {item?.site?.alias?.trim()}
                </GridCell>
              ),
              initialWidth: 'auto',
            },
          ]}
          sortedColumnKey={'alias'}
        /> :
        <CardContent>
          <SimpleTable layout='fixed'>
            <SimpleTableRow>
              <SimpleTableCell>Alias</SimpleTableCell>
              <SimpleTableCell>Serial</SimpleTableCell>
            </SimpleTableRow>
            {data?.data?.map((item, index) => (
              <SimpleTableRow key={"simpletablerow_" + index}>
                <SimpleTableCell className='trunc-text'>{item?.alias}</SimpleTableCell>
                <SimpleTableCell className='trunc_text'>{item?.serial}</SimpleTableCell>
              </SimpleTableRow>
            ))}
          </SimpleTable>
        </CardContent>
      }
    </CardWithHeader>
  );
};

export const Receipt = memo(ReceiptComponent);
