import React, { memo, FC } from 'react';
import Grid, { IReactGridColumn } from '$components/grid/react-grid/grid.react';
import {
  ControllerManagerListQueryVariables,
  ControllersSortEnum,
  InputMaybe,
} from '$typings/graphql-codegen';
import { UseGridDataReturnType } from '$components/grid/react-grid/gridhelper';
import { useControllerListSelectableRowsConfig } from '../../hooks/useControllerListSelectableRowsConfig';
import { IFilterGroup } from '$interfaces/iFilter';
import { usePersistedParsedState } from '$lib/hooks/usePersistedState';
import { ControllerManagerListRow } from '../../utils/controller-manager-list-row';
import { ControllerService } from '$services/controllerService';
import { useNavigate } from 'react-router-dom';

const defaultSelectedColumns = [
  ControllersSortEnum.Serial,
  ControllersSortEnum.AlternateSerial,
  ControllersSortEnum.Alias,
  ControllersSortEnum.Name,
  ControllersSortEnum.ControllerType,
  ControllersSortEnum.IpAddress,
  ControllersSortEnum.Buid,
  ControllersSortEnum.Version,
  ControllersSortEnum.SiteName,
  ControllersSortEnum.LastSampleTime,
  ControllersSortEnum.Created,
  ControllersSortEnum.CreatedBy,
];

export interface IGridWrapperProps {
  navigateToCreateNewSites: (controllerId: string | string[]) => unknown;
  navigateToAttachToSites: (controllerId: string | string[]) => unknown;

  name: string;
  variables: ControllerManagerListQueryVariables;
  gridData: UseGridDataReturnType<ControllerManagerListRow>;
  columns: IReactGridColumn<ControllerManagerListRow>[];
  columnEditMode?: boolean;
  activeFilters?: IFilterGroup[];
  filtersChanged?: React.Dispatch<
    React.SetStateAction<IFilterGroup[] | undefined>
  >;

  setVariables: React.Dispatch<
    React.SetStateAction<ControllerManagerListQueryVariables>
  >;
  aureliaControllerService: ControllerService;
}

const GridWrapperComponent: FC<IGridWrapperProps> = ({
  variables,
  gridData,
  navigateToAttachToSites,
  navigateToCreateNewSites,
  setVariables,
  aureliaControllerService,
  ...rest
}) => {
  const navigate = useNavigate();

  const [selectedColumns, setSelectedColumns] = usePersistedParsedState<any[]>(
    'controllerlist-selectedcolumns',
    defaultSelectedColumns
  );
   
  const selectableRowsConfig = useControllerListSelectableRowsConfig(
    variables,
    gridData,
    navigateToCreateNewSites,
    navigateToAttachToSites,
    aureliaControllerService
  );

  const rowLink = (row: ControllerManagerListRow) => {
    if (!row) return;
    return `controllermanager/${row.controllerId}`;
  };

  const onRowClick = (row: ControllerManagerListRow) => () =>
    navigate(`${row.controllerId}`, { replace: true });

  const isArray = (value: any) => Array.isArray(value);
  const isString = (value: any) => typeof value === 'string';
  const isStringArray = (value: any[]) => isArray(value) && value.every(isString);

  const asStringArray = (value: InputMaybe<string | string[]>) : string[] | undefined => {    
    if (!value)
      return undefined;

    if (isString(value))
        return [value as string];
    else if (isArray(value) && isStringArray(value as any[]))
      return value as string[];
    
    return undefined;
  }

  return (
    <Grid
      {...rest}
      rowLink={rowLink}
      onRowClick={onRowClick}
      selectableRowsConfig={selectableRowsConfig}
      items={gridData}
      selectedColumns={selectedColumns}
      selectedColumnsChanged={setSelectedColumns}
      sortedDirection={variables.sortDirection}
      sortedColumnKey={variables.sortProperty}
      sortChanged={(sortProperty, sortDirection) =>
        setVariables((v) => ({ ...v, sortProperty, sortDirection }))
      }
      freeTextQuery={asStringArray(variables.freeTextQuery)}
      highlightText={variables.freeTextQuery || undefined}
      loading={gridData.isRevalidating && !gridData.data}
    />
  );
};
export const GridWrapper = memo(GridWrapperComponent);
