import React, {
  FC,
  memo,
  MouseEvent,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/styled';

interface IHorizontalSplitterProps {
  firstComponent: ReactNode;
  minPxHeightFirst: number;
  secondComponent: ReactNode;
  minPxHeightSecond: number;

  // if you want to hoist the state you can do that
  firstHeightPercentage?: string;
  setFirstHeightPercentage?: (newHeight: string) => unknown;

  // default size only used if using internal state (not hoisted)
  initialSizePercentage?: string;
}

const Wrapper = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex: 1 0 0;
`;

const HorizontalDivider = styled.div`
  padding: 0.5rem;
  display: flex;
  cursor: ns-resize;
`;

const InnerHorizontalDivider = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  height: 6px;
  flex: 1;
  align-items: center;
  justify-content: center;

  transition: background-color ease 500ms, color ease 500ms;
  background-color: #efefef;
  color: #ccc;
  font-size: 18px;

  &:hover {
    background-color: #ccc;
    color: #999;
    transition: background-color ease 200ms, color ease 200ms;
  }
`;

function calcPercentage(newSize: number, boundingSize: number) {
  return ((newSize / boundingSize) * 100).toFixed(2);
}

let lastMouseY = 0;

/* If you came here looking for a VerticalSplitter/resizable divider you'll
need to refactor/or copy the logic in planner-page.tsx. A vertical splitter is 
implemented there, just not as its own component... yet */

const HorizontalSplitterComponent: FC<IHorizontalSplitterProps> = ({
  firstComponent,
  minPxHeightFirst,
  secondComponent,
  minPxHeightSecond,
  firstHeightPercentage,
  setFirstHeightPercentage,
  initialSizePercentage,
}: IHorizontalSplitterProps) => {
  const pageRef = useRef<HTMLDivElement>(null);
  const firstInnerDivRef = useRef<HTMLDivElement>(null);

  const [firstHeightPercentageInternal, setFirstHeightPercentageInternal] =
    useState(initialSizePercentage ?? '20%');

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMoveY, true);
  };

  const handleMouseDown = (e: MouseEvent) => {
    e.preventDefault();
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMoveY, true);
  };

  const handleMouseMoveY = useCallback(
    (e) => {
      if (!firstInnerDivRef.current || !pageRef.current) return;

      if (e.clientY === lastMouseY) return;
      lastMouseY = e.clientY; // throttle the event to 1px

      const firstInnerDivRefRect =
        firstInnerDivRef.current.getBoundingClientRect();
      if (!firstInnerDivRefRect) {
        return;
      }
      const pageRect = pageRef.current.getBoundingClientRect();

      const topOffset = firstInnerDivRefRect.top;

      // -9px to center the cursor on the divider
      const newHeight = e.clientY - topOffset - 9;

      const maxFirstHeight = pageRect.height - minPxHeightSecond;
      if (newHeight > minPxHeightFirst && newHeight < maxFirstHeight) {
        const percentage = calcPercentage(newHeight, pageRect.height) + '%';
        (setFirstHeightPercentage ?? setFirstHeightPercentageInternal)(
          percentage
        );
      }
    },
    [firstInnerDivRef, pageRef]
  );

  return (
    <Wrapper ref={pageRef}>
      {firstComponent && (
        <InnerWrapper
          ref={firstInnerDivRef}
          style={{
            flex:
              '0 0 ' + (firstHeightPercentage ?? firstHeightPercentageInternal),
          }}
        >
          {firstComponent}
        </InnerWrapper>
      )}
      {firstComponent && secondComponent && (
        <HorizontalDivider onMouseDown={handleMouseDown}>
          <InnerHorizontalDivider>
            <i style={{ paddingTop: '2px' }} className="fa fa-ellipsis-h" />
          </InnerHorizontalDivider>
        </HorizontalDivider>
      )}
      {secondComponent && <InnerWrapper>{secondComponent}</InnerWrapper>}
    </Wrapper>
  );
};

export const HorizontalSplitter = memo(HorizontalSplitterComponent);
