import React, { FC, memo } from 'react';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react'
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react'
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react'
import { Address } from '../../shipto-lookup'
import './shipto-lookup-item.css'

type IShipToLookupItemProps = {
    soldTo: string
    shipTo: string
    address: Address
    onClick: (selectedShipTo: string) => void
    isSelected: boolean
}

const ShipToLookupItemComponent: FC<IShipToLookupItemProps> = ({
    soldTo,
    shipTo,
    address,
    onClick,
    isSelected
}) => {
    return (
        <div onClick={() => onClick(shipTo)} style={{ margin: "10px 0px 10px 0", border: '1px solid black' }}>
            <SimpleTable>
                <SimpleTableRow className={`data-row ${isSelected ? 'selected' : ''}`}>
                    <SimpleTableCell className='address-cell'>
                        <span>{address.street}</span>
                        <span>{`${address.postalCode} ${address.city} ${address.country}`}</span>
                    </SimpleTableCell>
                    <SimpleTableCell className='data-cell'>
                        <span><b>SoldTo:</b> {soldTo}</span>
                        <span><b>ShipTo:</b> {shipTo}</span>
                    </SimpleTableCell>
                </SimpleTableRow>
            </SimpleTable>
        </div>
    );
}
export const ShipToLookupItem = memo(ShipToLookupItemComponent);