import {createContext, useContext} from "react";

export enum ActiveSlideoutComponent {
    None,
    ColumnEditor,
    CreateController,
    CreateSite,
    SwapController
}

export interface ControllerManagerContext {
    activeSlideoutComponent: ActiveSlideoutComponent,
    setActiveSlideoutComponent: (activeComponent: ActiveSlideoutComponent) => void
}

export const ControllerManagerContext = createContext<ControllerManagerContext | undefined>(undefined)

export default function useControllerManagerContext() {
    const ctx = useContext(ControllerManagerContext) as ControllerManagerContext;

    if(ctx === undefined)
        throw new Error("ControllerManagerContext is missing a provider")

    return ctx
}