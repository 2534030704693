import React, { FC, memo, useEffect, useState } from 'react';
import { useControllerCommandService } from '$lib/hooks/useControllerCommandService';
import { Button } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { IControllerCommandStatus } from '$interfaces/entity';
import { DateTime } from 'luxon';

interface IPingCellButtonProps {
  controllerId: number;
}

type PingStatus = 'success' | 'failed' | 'not started' | 'active';

const getButtonText = (status: PingStatus) => {
  switch (status) {
    case 'active':
      return 'UI_SiteDetails_Controllers_ControllerDetails_Actions_Pinging';
    case 'success':
      return 'UI_SiteDetails_Controllers_ControllerDetails_Actions_Ping_Success';
    case 'failed':
      return 'UI_SiteDetails_Controllers_ControllerDetails_Actions_Ping_Failed';
    default:
      return 'UI_SiteDetails_Controllers_ControllerDetails_Actions_Ping';
  }
};
const getButtonColor = (status: PingStatus) => {
  switch (status) {
    case 'success':
      return 'success';
    case 'failed':
      return 'error';
    default:
      return 'primary';
  }
};

const PingCellButtonComponent: FC<IPingCellButtonProps> = ({
  controllerId,
}: IPingCellButtonProps) => {
  const [t] = useCaseInsensitiveTranslation();
  const { sendCommand, commandHistory } = useControllerCommandService(controllerId);

  const getPingStatusFromHistory  = () : PingStatus => {
    //No history available
    if (!commandHistory?.length) {
      return 'not started';
    }

    //Find latest ping command (first in history table)
    const latestPing = commandHistory.find((c) => c.command === 'ping');

    if (latestPing) 
    {
      if (latestPing.ended) { //Finished
        const minsFromNow = DateTime.fromJSDate(latestPing.ended)
        .diffNow()
        .get('minute');
        
        if (minsFromNow <= 2) {
          return latestPing.status == IControllerCommandStatus.Success
              ? 'success'
              : latestPing.status == IControllerCommandStatus.Failed
              ? 'failed'
              : 'not started'; // ended is not undefined/null, so this should never happen                  
        } 
      } else {
        //Still going        
        return 'active';
      }      
    }

    return 'not started';
  }

  const [pingStatus, setPingStatus] = useState<PingStatus>(getPingStatusFromHistory);
  
  useEffect(() => {
    updateFromCommandHistory();
  }, [commandHistory]);

  const updateFromCommandHistory = () => {
    setPingStatus(getPingStatusFromHistory());
  };

  const ping = () =>
      sendCommand(
        'ping',
        undefined,
         () => updateFromCommandHistory(),
         () => updateFromCommandHistory(),
         () => updateFromCommandHistory()        
      );

  return (
    <Button
      fullWidth
      variant="outlined"
      color={getButtonColor(pingStatus)}      
      startIcon={
        pingStatus == 'active' ? (
          <Icon name="fa-spinner fa-pulse" className="text" />
        ) : (
          pingStatus == 'success' ? <Icon name="fa-check" className="text" /> : ''
        )
      }
      disabled={pingStatus == 'active'}
      onClick={ping}
    >
      {t(getButtonText(pingStatus))}
    </Button>
  );
};

export const PingCellButton = memo(PingCellButtonComponent);
