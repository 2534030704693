import React, { useState, FC, memo, useEffect } from 'react';
import ButtonGroup from '$components/button-group/button-group.react';
import Button from '$components/buttons/button.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { ControllerDeleteModal } from '$pages/common/controllers/controllerdetails-react/modules/modals/controller-delete-modal';
import { ControllerArchiveModal } from '$pages/common/controllers/controllerdetails-react/modules/modals/controller-archive-modal';
import { ensureNumber } from '$lib/numberHelpers';
import { ControllerDetachModal } from '$pages/common/controllers/controllerdetails-react/modules/modals/controller-detach-modal';
import { ControllerService } from '$services/controllerService';
import { revalidateAllActiveQueries } from '$pages/common/GraphQLFetcher';

interface ISelectActionsProps {
  selectedControllerIds: number[];
  clearSelectedIds: () => void;
  showDetach: boolean;
  navigateToCreateNewSites: (controllerId: string | string[]) => unknown;
  navigateToAttachToSites: (controllerId: string | string[]) => unknown;
  aureliaControllerService: ControllerService;
}

const SelectActionsComponent: FC<ISelectActionsProps> = ({
  selectedControllerIds,
  clearSelectedIds,
  showDetach,
  navigateToAttachToSites,
  navigateToCreateNewSites,
  aureliaControllerService,
}) => {
  const [showDetachConfirmation, setShowDetachConfirmation] = useState(false);  
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false);
  const [renderDetachModal, setRenderDetachModal] = useState(false);
  const [renderDeleteModal, setRenderDeleteModal] = useState(false);
  const [renderArchiveModal, setRenderArchiveModal] = useState(false);
  const [t] = useCaseInsensitiveTranslation();

  const controllerIds = selectedControllerIds.map((i) => ensureNumber(i));

  useEffect(() => {
    if (!renderDetachModal && showDetachConfirmation) setRenderDetachModal(true);
  }, [showDetachConfirmation]);

  useEffect(() => {
    if (!renderDeleteModal && showDeleteConfirmation) setRenderDeleteModal(true);
  }, [showDeleteConfirmation]);
  
  useEffect(() => {
    if (!renderArchiveModal && showArchiveConfirmation) setRenderArchiveModal(true);
  }, [showArchiveConfirmation]);

  const closeDelete = () => {
    setShowDeleteConfirmation(false);
  };
  const onDeleted = () => {
    clearSelectedIds();
    closeDelete();
    revalidateAllActiveQueries();
  };

  const closeArchive = () => {
    setShowArchiveConfirmation(false);
  };
  
  const onArchived = () => {
    clearSelectedIds();
    closeDelete();
    revalidateAllActiveQueries();
  };

  const closeDetach = () => {
    setShowDetachConfirmation(false);
  };
  
  return (
    <div className="flex">
      {showDetach && (
        <Button
          variant="secondary"
          onClick={() => setShowDetachConfirmation(true)}
        >
          {t(
            'UI_ControllerManager_ControllerList_SelectionBar_DetachControllers'
          )}
        </Button>
      )}

      <ButtonGroup className="flex mar_lm">
        <Button
          variant="secondary"
          onClick={() =>
            navigateToAttachToSites(
              selectedControllerIds.map((id) => id?.toString())
            )
          }
        >
          {t(
            'UI_ControllerManager_ControllerList_SelectionBar_AttachToExisting'
          )}
        </Button>
        <Button
          variant="secondary"
          onClick={() =>
            navigateToCreateNewSites(
              selectedControllerIds.map((id) => id?.toString())
            )
          }
        >
          {t('UI_ControllerManager_ControllerList_SelectionBar_CreateNewSites')}
        </Button>
      </ButtonGroup>

      <Button
        variant="secondary"
        onClick={() => setShowArchiveConfirmation(true)}
        className="mar_lm"
      >
        {t(
          'UI_ControllerManager_ControllerList_SelectionBar_ArchiveControllers'        
        )}
      </Button>
      
      {renderArchiveModal && (
        <ControllerArchiveModal
          open={showArchiveConfirmation}
          controllerIds={controllerIds}
          handleClose={closeArchive}
          onArchived={onArchived}
        />
      )}

      <Button
        variant="danger"
        onClick={() => setShowDeleteConfirmation(true)}
        className="mar_lm"
      >
        {t(
          'UI_ControllerManager_ControllerList_SelectionBar_DeleteControllers'
        )}
      </Button>
      {renderDeleteModal && (
        <ControllerDeleteModal
          open={showDeleteConfirmation}
          controllerIds={controllerIds}
          handleClose={closeDelete}
          onDeleted={onDeleted}
        />
      )}
      {renderDetachModal && (
        <ControllerDetachModal
          open={showDetachConfirmation}
          controllerIds={controllerIds}
          handleClose={closeDetach}
          aureliaControllerService={aureliaControllerService}
        />
      )}
    </div>
  );
};

export const SelectActions = memo(SelectActionsComponent);
