import { autoinject } from 'aurelia-framework';
import { BaseViewModel } from '../common';
import { getLogger } from 'aurelia-logging';
import { Router, RouterConfiguration } from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';
import { routeNames } from '../../config';
import './controllermanager.css';

@autoinject()
export class Controllers extends BaseViewModel<void> {
  router: Router;

  constructor() {
    super(getLogger('Controllermanager'));
  }

  configureRouter(config: RouterConfiguration, router: Router) {
    config.map([
      /*{
        route: 'create/:controllerIds',
        name: routeNames.controllermanagerCreateSite,
        settings: { menuTitle: 'Create site' },
        moduleId: PLATFORM.moduleName(
          'pages/controllermanager/createsite/createsite'
        )
      },
      {
        route: 'attach/:controllerIds',
        name: routeNames.controllermanagerAttachSite,
        settings: { menuTitle: 'Attach site' },
        moduleId: PLATFORM.moduleName(
          'pages/controllermanager/attachcontrollers/attachcontrollers'
        )
      },
      {
        route: 'receipt/:controllerIds/:type',
        name: routeNames.controllermanagerReceipt,
        settings: { menuTitle: 'Receipt' },
        moduleId: PLATFORM.moduleName(
          'pages/controllermanager/controllermanagerreceipt/controllermanagerreceipt'
        )
      },*/
      {
        route: ['', ':controllerId', 'createcontroller', 'create/:controllerIds', 'attach/:controllerIds', 'receipt/:controllerIds/:type', 'replace/comparison', 'replace/comparison/status/:jobId' ],
        name: routeNames.controllerManagerControllerList,
        settings: { menuTitle: 'ControllerManager' },
        moduleId: PLATFORM.moduleName(
          'pages/controllermanager/controllermanager-react/controllermanagerwrapper'
        )
      },
      {
        route: ':controllerId/channels/:action?/:channelId?',
        name: routeNames.controllerManagerChannels,
        settings: { menuTitle: 'Channels' },
        moduleId: PLATFORM.moduleName(
          'pages/controllermanager/controllerchannels/controllerchannels'
        )
      },
      {
        route: ':controllerId/profile/:profileId/:historyId?',
        name: routeNames.sitedetailsProfileDetails,
        moduleId: PLATFORM.moduleName(
          'pages/common/controllers/controller-profile-editor/controller-profile-editor-wrapper',
          'sitedetails-profiledetails'
        )
      },
      {
        route: ':controllerId',
        name: routeNames.controllerManagerControllerDetails,
        moduleId: PLATFORM.moduleName(
          'pages/controllermanager/controllermanager-react/controllermanagerwrapper',
        )
      }
    ]);
    this.router = router;
  }
}
