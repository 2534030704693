import React, { FC } from 'react';
import RadioButtonItem from '$components/radio-buttons/radio-button-item/radio-button-item.react'
import './radio-button-group.css'

interface RadioButtonGroupProps<T> {
  options: {value: T, text?: string, disabled?: boolean}[];
  selectedOption: T | undefined;
  onChange: (option: T) => void;
  disabled?: boolean
}

const RadioButtonGroup: FC<RadioButtonGroupProps<any>> = ({ options, selectedOption, onChange, disabled }) => {
  return (
    <div className='radio-button-group'>
      {options.map(option => (
        <RadioButtonItem key={option.value} value={option.value} text={option.text} selectedOption={selectedOption} onChange={onChange} disabled={disabled || option.disabled}/>
      ))}
    </div>
  );
};

export default RadioButtonGroup;