import React, {
  FC,
  memo,
  useCallback,
  useState,
  Dispatch,
  SetStateAction,
  useRef
} from 'react';
import '../../controllerlistreact.css';
import { ControllerManagerListRow } from '../../utils/controller-manager-list-row';
import { ControllersSortEnum, SignalEnum } from '$typings/graphql-codegen';
import Card from '$components/cards/card/card.react';
import CardContent from '$components/cards/card-content/card-content.react';
import TextField from '$components/textbox/text-field.react';
import Icon from '$components/icons/icon/icon.react';
import DataFreshness from '$pages/common/data-freshness/data-freshness.react';
import { ElasticSearchPage } from '$typings/graphql';
import { ListRange, Virtuoso } from 'react-virtuoso';
import LoadingBars from '$components/loading-bars/loading-bars.react';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import Skeleton from '$components/placeholders/skeleton/skeleton';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { IReactGridColumn } from '$components/grid/react-grid/grid.react';
import { UseGridDataReturnType } from '$components/grid/react-grid/gridhelper';
import { Router } from 'aurelia-router';
import { ControllersAssignedStatus } from '../../controllerlist.react';
import { Button, ButtonGroup, IconButton } from '@mui/material';
import { CardWithHeader } from '$components/cards/mui-card';
import { IFilterGroup } from '$interfaces/iFilter';
import { routeNames } from '../../../../../../../config';
import { useNavigate } from 'react-router-dom';
import { ControllerListFilterList } from '../filter-list';
import { groupFilterDefinitions } from '$components/grid/react-grid/filter-list/filter-list';
import classNames from 'classnames';

interface IMobileControllerlistProps {
  gridData: UseGridDataReturnType<ControllerManagerListRow>;
  columns: IReactGridColumn<ControllerManagerListRow>[];
  freeTextQueryAsArray?: string[];
  freeTextQueryValue?: string;
  onFreeTextQueryChange: (query?: string) => unknown;
  aureliaRouter: Router;
  loadNewData: () => unknown;
  dataFreshnessPrefixText: string;
  filters?: IFilterGroup[];
  setFilters: Dispatch<SetStateAction<IFilterGroup[] | undefined>>;
  clearFilters: () => unknown;
  variablesHasChanged: boolean;
  assignedQuery?: ControllersAssignedStatus;
  setAssignedQuery: Dispatch<
    SetStateAction<ControllersAssignedStatus | undefined>
  >;
}

const MobileControllerlistComponent: FC<IMobileControllerlistProps> = ({
  gridData,
  columns,
  freeTextQueryAsArray,
  freeTextQueryValue,
  onFreeTextQueryChange,
  aureliaRouter,
  loadNewData,
  dataFreshnessPrefixText,
  filters,
  setFilters,
  clearFilters,
  variablesHasChanged,
  assignedQuery,
  setAssignedQuery
}) => {
  const lastViewedItems = useRef<ListRange>();
  const [showMobileFilterEditor, setShowMobileFilterEditor] = useState(false);
  const [t] = useCaseInsensitiveTranslation();
  const navigate = useNavigate();

  // prettier-ignore
  const getAvailableProperties = useCallback(
    (item: ControllerManagerListRow) => {
      if (!item) return [];
      const availableProperties = [];
      if (item.serial && columns.some((c) => c.columnKey === ControllersSortEnum.Serial)) {
        availableProperties.push({
          name: t('ui_controllermanager_controllerlist_table_headers_serial'),
          value: item.serial
        });
      }
      if (item.alternateSerial && columns.some((c) => c.columnKey === ControllersSortEnum.AlternateSerial)) {
        availableProperties.push({
          name: t(
            'ui_controllermanager_controllerlist_table_headers_alternateserial'
          ),
          value: item.alternateSerial
        });
      }
      if (item.controllerType && columns.some((c) => c.columnKey === ControllersSortEnum.ControllerType)) {
        availableProperties.push({
          name: t(
            'ui_controllermanager_controllerlist_table_headers_controllertype'
          ),
          value: item.controllerType
        });
      }
      if (item.protocolVersion && columns.some((c) => c.columnKey === ControllersSortEnum.Version)) {
        availableProperties.push({
          name: t('ui_controllermanager_controllerlist_table_headers_version'),
          value: item.protocolVersion
        });
      }
      if (item.buid?.name && columns.some((c) => c.columnKey === ControllersSortEnum.Buid)) {
        availableProperties.push({
          name: t('ui_controllermanager_controllerlist_table_headers_buid'),
          value: item.buid.name
        });
      }
      if (item.site?.id && columns.some((c) => c.columnKey === ControllersSortEnum.SiteName)) {
        availableProperties.push({
          name: t('ui_controllermanager_controllerlist_table_headers_site'),
          value: (
            <a
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                aureliaRouter.navigateToRoute(routeNames.sitedetails, {
                  id: item.site?.id
                });
              }}
            >
              {item.site?.name}
            </a>
          )
        });
      }
      return availableProperties;
    },
    [columns, t]
  );

  const { data, visibleItemsChanged, isRevalidating } = gridData;
  const grouped = groupFilterDefinitions(columns, t);
  return (
    <div className="controller-manager-controllerlist mobile-view">
      <Card className="mar_tm mar_lm mar_rm">
        <CardContent className="flex row aicenter filterbar">
          <div className="flex_1">
            <TextField
              icon={<Icon name={'fa-search'} />}
              value={freeTextQueryValue ?? ''}
              placeholder={t(
                'UI_ControllerManager_ControllerList_Filters_Textfilter_Placeholder'
              )}
              onChange={e => onFreeTextQueryChange(e.target.value)}
              className="controllerlist-searchfield"
            />
          </div>
          <div className="flex_0_1_auto">
            <IconButton
              /* very specific to align perfectly with the search field */
              sx={{ py: '8px', pl: '11px', pr: '11px', ml: '7px' }}
              onClick={() => setShowMobileFilterEditor(show => !show)}
              color={showMobileFilterEditor ? 'primary' : 'default'}
            >
              <Icon className="filter-icon" name={'fa-filter'} />
            </IconButton>
          </div>
        </CardContent>
      </Card>
      <DataFreshness
        pageToCheck={ElasticSearchPage.ControllerManager}
        freshnessChanged={loadNewData}
        prefixedText={dataFreshnessPrefixText}
        className="flex jccenter mar_m jsb"
      />
      {showMobileFilterEditor ? (
        <Card className="mobile-filter-editor">
          <CardContent className="mobile-filter-editor-content">
            <ControllerListFilterList
              freeTextQuery={freeTextQueryAsArray}
              filtersChanged={setFilters}
              groupedFilterDefinitions={grouped}
              activeFilters={filters}
              variablesHasChanged={variablesHasChanged}
              resetAllFilters={clearFilters}
              assignedToggle={
                <div className="flex aicenter mar_m">
                  <ButtonGroup className="ai-start flex flex_1">
                    <Button
                      className="flex_1"
                      variant={
                        assignedQuery === ControllersAssignedStatus.Unassigned
                          ? 'contained'
                          : 'outlined'
                      }
                      onClick={() =>
                        setAssignedQuery(ControllersAssignedStatus.Unassigned)
                      }
                    >
                      {t(
                        'UI_ControllerManager_ControllerList_Filters_AssignedStatus_Unassigned'
                      )}
                    </Button>
                    <Button
                      className="flex_1"
                      variant={
                        assignedQuery === ControllersAssignedStatus.Assigned
                          ? 'contained'
                          : 'outlined'
                      }
                      onClick={() =>
                        setAssignedQuery(ControllersAssignedStatus.Assigned)
                      }
                    >
                      {t(
                        'UI_ControllerManager_ControllerList_Filters_AssignedStatus_Assigned'
                      )}
                    </Button>
                    <Button
                      className="flex_1"
                      variant={
                        assignedQuery === undefined ? 'contained' : 'outlined'
                      }
                      onClick={() => setAssignedQuery(undefined)}
                    >
                      {t(
                        'UI_ControllerManager_ControllerList_Filters_AssignedStatus_Both'
                      )}
                    </Button>
                  </ButtonGroup>
                </div>
              }
            />
            <div className="flex_0_0_auto flex column jcflexend mar_bm mar_rm mar_lm ">
              <div className="flex jsb">
                <Button
                  variant="outlined"
                  className="flex_1"
                  onClick={() => setShowMobileFilterEditor(false)}
                >
                  {t('UI_Common_Show') + ' ' + dataFreshnessPrefixText}
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      ) : (
        <>
          <Virtuoso
            overscan={50}
            data={data}
            components={{
              EmptyPlaceholder: () => (
                <>{isRevalidating && !data && <LoadingBars />}</>
              )
            }}
            rangeChanged={i => {
              if (i.startIndex > 0) {
                visibleItemsChanged(i);
              }
              lastViewedItems.current = i;
            }}
            itemContent={(_index, item: ControllerManagerListRow) => {
              const availableProperties = getAvailableProperties(item);
              return (
                <div
                  key={_index}
                  onClick={() => {
                    navigate(`${item.controllerId}`);
                  }}
                >
                  {item !== undefined ? (
                    <CardWithHeader
                      className="mar_bm mar_rm mar_lm"
                      headerText={
                        item.name
                          ? item.name
                          : item.alias
                          ? item.alias
                          : 'Name/alias missing'
                      }
                      headerActions={
                        <div style={{ maxWidth: '30px' }}>
                          {/* fixes weird flex error on webkit browsers */}
                          <Icon
                            name={
                              item.signal === SignalEnum.Offline
                                ? 'signal-bad'
                                : item.signal === SignalEnum.Stale
                                ? 'signal-poor'
                                : 'signal-good'
                            }
                            className="signal-icon"
                          />
                        </div>
                      }
                    >
                      <SimpleTable layout={'fixed'}>
                        {availableProperties.length === 0 && (
                          <SimpleTableRow>
                            <SimpleTableCell className="simple-list-cell bg left-col">
                              The controller has no available properties
                            </SimpleTableCell>
                            <SimpleTableCell className="simple-list-cell bg" />
                          </SimpleTableRow>
                        )}
                        {availableProperties.map((p, i) => {
                          const tableCellClass = `simple-list-cell ${
                            i % 2 ? '' : 'bg'
                          }`;
                          return (
                            <SimpleTableRow key={`controllerlist_${p.name}`}>
                              <SimpleTableCell className={classNames(tableCellClass, 'left-col')}>
                                {p.name}
                              </SimpleTableCell>
                              <SimpleTableCell className={tableCellClass}>
                                {p.value}
                              </SimpleTableCell>
                            </SimpleTableRow>
                          );
                        })}
                      </SimpleTable>
                    </CardWithHeader>
                  ) : (
                    <CardWithHeader>
                      <Skeleton rowCount={6} columnCount={1} />
                    </CardWithHeader>
                  )}
                </div>
              );
            }}
          />
        </>
      )}
    </div>
  );
};

export const MobileControllerlist = memo(MobileControllerlistComponent);
