import React, { FC, memo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import './create-new-site-from-ticket.css'
import FormElement from '$components/forms/form-element/form-element.react';
import Card from "$components/cards/card/card.react";
import CardContent from "$components/cards/card-content/card-content.react";
import CardFooter from "$components/cards/card-footer/card-footer.react";
import CardHeader from '$components/cards/card-header/card-header.react';
import TextField from "$components/textbox/text-field.react";
import { CreateSiteFromServiceNowTicketInput } from '$typings/graphql-codegen';
import { mutate } from '$lib/hooks/fetch-utillities';
import Button from "../../../../../components/buttons/button.react"
import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation";
import {
  GetServiceNowCreateSiteTicketDocument,
  CreateSiteFromServiceNowTicketDocument,
  GetUnassignedControllerBySerialDocument,
  GetCustomerBySoldToDocument,
  GetSiteByShipToDocument
} from '$typings/graphql-codegen';
import { ShipToLookup } from './modules/shipto-lookup/shipto-lookup'
import CustomerDropdown from '$components/dropdowns/customer-dropdown/customer-dropdown.react';
import BuidDropdown from '$components/dropdowns/buid-dropdown/buid-dropdown.react';
import ProductDropdown from '$components/dropdowns/product-dropdown/product-dropdown.react';
import UnitDropdown from '$components/dropdowns/unit-dropdown/unit-dropdown.react';
import { isNone, isSomething } from '$lib/helpers';
import ErrorText from '$components/texts/error-text/error-text.react';
import { WarningText } from '$components/texts/warning-text/warning-text.react';
import Icon from '$components/icons/icon/icon.react';

import useControllerManagerContext, { ActiveSlideoutComponent } from '../controller-list/contexts/controller-manager-context';

export interface ICreateSiteFromTicketFormValues {
  ticketNumber: string;
  ticketUrl: LinkData;
  onBehalfOf: string;
  siteName: string;
  serial: string;
  controllerId: number | null;
  controllerType: string | null;
  buid: string;
  buidId: number | null;
  accessTag: number | null;
  soldTo: string;
  shipTo: string;
  product: string;
  productId: number | null;
  tankCapacity: string;
  tankMaximumPercentage: string;
  tankMaximum: string;
  tankMinimumPercentage: string;
  tankMinimum: string;
  unitId: number | null;
  externalUsersWithAccount: string;
  yaraUsers: string;
}

type ICreateSiteFromTicketProps = {
}

type LinkData = {
  url: string;
  text: string;
}

const CreateSiteFromTicketComponent: FC<ICreateSiteFromTicketProps> = ({
}) => {

  const ctx = useControllerManagerContext();
  const [t] = useCaseInsensitiveTranslation()
  const [ticketFound, setTicketFound] = useState(false);
  const [isFetchingTicket, setIsFetchingTicket] = useState(false);
  const [shipToSearchMode, setShipToSearchMode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shipToInUseWarning, setShipToInUseWarning] = useState<LinkData | undefined>(undefined)
  const [isMTDevice, setIsMTDevice] = useState(false);
  const [accessTagWarning, setAccessTagWarning] = useState('')
  const [newlyCreatedSite, setNewlyCreatedSite] = useState<LinkData | undefined>(undefined)
  const [createSiteError, setCreateSiteError] = useState('')
  const {
    control,
    register,
    setValue,
    handleSubmit,
    setError,
    trigger,
    watch,
    clearErrors,
    getValues,
    formState: { errors, isValid }
  } = useForm<ICreateSiteFromTicketFormValues>({
    mode: 'onChange', defaultValues: {
      unitId: 1,
      productId: null
    }
  });

  const watchSiteName = watch('siteName');
  const watchSoldTo = watch('soldTo');
  const watchCapacity = watch('tankCapacity')
  const watchTankMin = watch('tankMinimum')
  const watchTankMax = watch('tankMaximum')

  const ensureString = (item: string | null | undefined): string => {
    return !!item ? item : '';
  };

  async function getServiceNowCreateSiteTicket(ticketNumber: string) {

    const variables = {
      ticketNumber: ticketNumber
    }

    setIsFetchingTicket(true);
    const ticket = await mutate(GetServiceNowCreateSiteTicketDocument, variables, false);
    setIsFetchingTicket(false);

    if (!ticket?.getServiceNowCreateSiteTicket?.success) {
      setError('ticketNumber', {
        message: t(ensureString(ticket?.getServiceNowCreateSiteTicket?.message))
      });

      setTicketFound(false);
      return;
    }

    clearErrors();
    setTicketFound(true);

    console.log('ticket', ticket.getServiceNowCreateSiteTicket?.content);
    
    setValue('siteName', ensureString(ticket.getServiceNowCreateSiteTicket?.content?.siteName))
    setValue('ticketUrl', { text: ticketNumber, url: ensureString(ticket.getServiceNowCreateSiteTicket?.content?.serviceNowUrl) })
    setValue('onBehalfOf', ensureString(ticket.getServiceNowCreateSiteTicket?.content?.onBehalfOf))
    setValue('buid', ensureString(ticket.getServiceNowCreateSiteTicket?.content?.buid))
    setValue('buidId', ticket.getServiceNowCreateSiteTicket?.content?.buidId as number ?? null)
    setValue('soldTo', ensureString(ticket.getServiceNowCreateSiteTicket?.content?.soldTo))
    setValue('shipTo', ensureString(ticket.getServiceNowCreateSiteTicket?.content?.shipTo))
    setValue('product', ensureString(ticket.getServiceNowCreateSiteTicket?.content?.product))
    setValue('productId', ticket.getServiceNowCreateSiteTicket?.content?.productId as number ?? null)
    setValue('tankCapacity', ensureString(ticket.getServiceNowCreateSiteTicket?.content?.tankCapacity))
    setValue('tankMaximum', ensureString(ticket.getServiceNowCreateSiteTicket?.content?.tankMaximum))
    setValue('tankMaximumPercentage', ConvertValueToPercentage(ensureString(ticket.getServiceNowCreateSiteTicket?.content?.tankMaximum), ensureString(ticket.getServiceNowCreateSiteTicket?.content?.tankCapacity)))
    setValue('tankMinimum', ensureString(ticket.getServiceNowCreateSiteTicket?.content?.tankMinimum))
    setValue('tankMinimumPercentage', ConvertValueToPercentage(ensureString(ticket.getServiceNowCreateSiteTicket?.content?.tankMinimum), ensureString(ticket.getServiceNowCreateSiteTicket?.content?.tankCapacity)))
    setValue('externalUsersWithAccount', ensureString(ticket.getServiceNowCreateSiteTicket?.content?.externalUsersWithAccount))
    setValue('yaraUsers', ensureString(ticket.getServiceNowCreateSiteTicket?.content?.yaraUsers))
    setValue('serial', ticket.getServiceNowCreateSiteTicket?.content?.controllerSerial as string)

    if (ticket.getServiceNowCreateSiteTicket?.content?.shipTo) {
      await checkIfShipToInUse(ticket.getServiceNowCreateSiteTicket.content.shipTo as string);
    }

    if (ticket.getServiceNowCreateSiteTicket?.content?.soldTo) {
      await tryGetAccessTagBySoldTo(ticket.getServiceNowCreateSiteTicket?.content?.soldTo as string)
    }

    await trigger();

  }

  function ConvertValueToPercentage(value: string, capacity: string) {
    if (!value || !capacity)
      return '';

    const val = Math.round(parseFloat(value) / parseFloat(capacity) * 100)
    return val.toString();

  }

  function ConvertPercentageToValue(percentage: string, capacity: string) {
    if (!percentage || !capacity)
      return '';

    const val = Math.round(parseFloat(capacity) * (parseFloat(percentage) / 100));
    return val.toString();

  }

  const checkIfShipToInUse = async (shipTo: string) => {

    if (!shipTo) { return }

    const variables = {
      shipTo: shipTo
    }

    const siteByShipTo = await mutate(GetSiteByShipToDocument, variables, false);

    if (isSomething(siteByShipTo.siteByShipTo?.siteId)) {
      setShipToInUseWarning({
        url: `/sitedetails/${siteByShipTo.siteByShipTo?.siteId}`,
        text: ensureString(siteByShipTo.siteByShipTo?.name)
      })
    }
    else {
      setShipToInUseWarning(undefined);
    }
  };

  async function tryGetAccessTagBySoldTo(soldTo: string) {

    if (!soldTo) { return }

    clearErrors('accessTag');
    const customerBySoldTo = await mutate(GetCustomerBySoldToDocument, { soldTo: soldTo }, false);

    if (customerBySoldTo.getCustomerBySoldTo?.success) {

      if(customerBySoldTo.getCustomerBySoldTo?.content && customerBySoldTo.getCustomerBySoldTo?.content.length == 1){
        setValue('accessTag', parseInt(customerBySoldTo.getCustomerBySoldTo?.content[0]?.customerId as string))
        setAccessTagWarning(t(`ui_controllermanager_servicenow_createsite_suggested_access_tag_by_soldto`, { soldTo: soldTo }))
      }
      else{

        let warningText = t(`ui_controllermanager_servicenow_createsite_validation_multiple_accesstag_for_soldto`, { soldTo: soldTo }) + '\n';
        
        customerBySoldTo.getCustomerBySoldTo?.content?.forEach(customer => {
        warningText += `${customer?.name} \n`;

      });

      setAccessTagWarning(warningText)

      }
 
    }
    else {
      setAccessTagWarning(t('ui_controllermanager_servicenow_createsite_validation_no_accesstag_for_soldto', { soldTo: soldTo }))
    }

    await trigger('accessTag')
  }

  const checkIfControllerIsUnassigned = async (controllerSerial: string): Promise<[boolean, number | undefined, string | undefined]> => {
    if (!controllerSerial) {
      return [false, undefined, undefined];
    }

    const controllerVars = {
      serial: controllerSerial
    };

    const unAssignedController = await mutate(GetUnassignedControllerBySerialDocument, controllerVars, false);
    
    if (isNone(unAssignedController.unassignedControllerBySerial?.controller)) {
      return [false, undefined, undefined];
    } else {
      const controllerId = unAssignedController.unassignedControllerBySerial?.controller?.controllerId ? +unAssignedController.unassignedControllerBySerial?.controller?.controllerId : undefined
      const controllerType = unAssignedController.unassignedControllerBySerial?.controller?.controllerType ? unAssignedController.unassignedControllerBySerial?.controller?.controllerType.trim() : undefined;

      setIsMTDevice(controllerType?.trim()?.toLowerCase() === 'mt device');
      
      return [true, controllerId, controllerType];
    }
  }

  function debounce<T extends (...args: any[]) => any>(
    func: T,
    delay: number
  ): (...args: Parameters<T>) => Promise<ReturnType<T>> {
    let timerId: NodeJS.Timeout;
    return async function debouncedFunction(
      ...args: Parameters<T>
    ): Promise<ReturnType<T>> {
      clearTimeout(timerId);
      return new Promise((resolve) => {
        timerId = setTimeout(async () => {
          const result = await func(...args);
          resolve(result);
        }, delay);
      });
    };
  }

  async function isValidSerial(serial: string) {
    const [isUnassigned, controllerId, controllerType] = await checkIfControllerIsUnassigned(serial);
    
    if (isUnassigned) {
      setValue('controllerId', controllerId as number)
      setValue('controllerType', controllerType as string)
      return true;
    }

    return false;
  }

  //The react hook form doesnt forces revalidation on all fields, so we have to debounce this in order for it to not be triggered a bunch on initial ticket fetch
  const debouncedIsValidSerial = debounce(isValidSerial, 300);

  const onSubmit: SubmitHandler<ICreateSiteFromTicketFormValues> = async (formData) => {

    if (isValid || !isSubmitting) {

      setIsSubmitting(true);

      try {
        const createSiteRequestData: CreateSiteFromServiceNowTicketInput = {
          ticketNumber: ensureString(formData.ticketNumber),
          controllerId: formData.controllerId!,
          buidId: formData.buidId!,
          accessTag: formData.accessTag!,
          siteName: formData.siteName,
          shipTo: formData.shipTo,
          soldTo: formData.soldTo,
          tankCapacity: +formData.tankCapacity ?? null,
          tankMinimum: (formData.tankCapacity && formData.tankMinimum) ? +formData.tankMinimum : null,
          tankMaximum: (formData.tankCapacity && formData.tankMaximum) ? +formData.tankMaximum : null,
          tankMaximumPercentage: (formData.tankCapacity && formData.tankMaximumPercentage) ? +formData.tankMaximumPercentage : null,
          tankMinimumPercentage: (isMTDevice || (formData.tankCapacity && formData.tankMinimumPercentage)) ? +formData.tankMinimumPercentage : null,
          productId: formData.productId ? formData.productId : null,
          unitId: formData.unitId!
        };

        const variables = {
          createSiteRequest: createSiteRequestData
        }

        const createResult = await mutate(
          CreateSiteFromServiceNowTicketDocument,
          variables,
          false,
        );

        if (createResult?.createSiteFromServiceNowTicket?.success) {
          const linkText = t('ui_controllermanager_servicenow_createsite_newsitecreated', { newSiteName: createResult?.createSiteFromServiceNowTicket?.content?.name });
          setNewlyCreatedSite({ text: linkText, url: `/sitedetails/${createResult.createSiteFromServiceNowTicket?.content?.siteId}` })
        }
        else {
          setCreateSiteError(t(ensureString(createResult?.createSiteFromServiceNowTicket?.message)));
        }
      }
      catch {
        //Errors handled
      }
      finally {
        setIsSubmitting(false);
        clearErrors();
      }
    }

  };

  const onShipToSearchModeCancel = () => {
    setShipToSearchMode(false);
  };

  const onShipToSearchModeSelect = async (shipTo: string) => {
    setValue('shipTo', shipTo);
    await checkIfShipToInUse(shipTo)
    setShipToSearchMode(false);
  };

  return (
    <div style={{ position: "relative", maxHeight: "100%", height: "100%", overflowY: "auto" }}>
      {shipToSearchMode && <ShipToLookup soldTo={watchSoldTo} siteName={watchSiteName} onSelect={onShipToSearchModeSelect} onCancel={onShipToSearchModeCancel} />}

      <CardHeader>
        <span>{t('ui_controllermanager_servicenow_createsite_header')}</span>
      </CardHeader>
      <CardContent>
      <p style={{ paddingBottom: "15px" }}>
          {t('ui_controllermanager_servicenow_createsite_description')}
        </p>
        {!ticketFound ? (
          <>
            <FormElement label={t('ui_controllermanager_servicenow_createsite_ticket')}>
              <Controller
                control={control}
                name="ticketNumber"
                render={({ field }) => (
                  <div style={{ display: "flex", gap: "4px" }}>
                    <TextField
                      {...register('ticketNumber')}
                      placeholder={t('ui_controllermanager_servicenow_createsite_ticket_search_placeholder')}
                    />
                    <Button icon={isFetchingTicket ? 'fa-spinner fa-pulse' : 'fa-search'} disabled={!field?.value || isFetchingTicket} onClick={() => getServiceNowCreateSiteTicket(field?.value)}></Button>
                  </div>)} />
            </FormElement>
            {errors.ticketNumber?.message &&
              <Card>
                <CardContent>
                  <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <ErrorText>{errors.ticketNumber?.message}</ErrorText>
                  </div>
                </CardContent>
              </Card>}
          </>
        )
          :
          (
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormElement label={t('ui_controllermanager_servicenow_createsite_ticket')}>
                  <Controller
                    control={control}
                    name="ticketUrl"
                    render={({ field }) => (
                      <div style={{ display: "flex", gap: "4px" }}>
                        <a href={field.value.url} target='_blank' rel='noreferrer'>{field.value.text}</a>
                      </div>)} />
                </FormElement>

                <FormElement label={t('ui_controllermanager_servicenow_createsite_onbehalfof')}>
                  <Controller
                    control={control}
                    name="onBehalfOf"
                    render={({ field }) => (
                      <div style={{ display: "flex", gap: "4px" }}>
                        {field.value}
                      </div>)} />
                </FormElement>

                <FormElement label={t('ui_controllermanager_servicenow_createsite_sitename')}>
                  <TextField
                    {...register('siteName', {
                      required: t('ui_controllermanager_servicenow_createsite_validation_required'),
                      validate: {
                        isRequired: (value) => {
                          if (!value) return t('ui_controllermanager_servicenow_createsite_validation_required')
                          else return
                        }
                      }
                    })}
                  />
                  <ErrorText>
                    {errors.siteName && errors.siteName?.message}
                  </ErrorText>
                </FormElement>

                <FormElement label={t('ui_controllermanager_servicenow_createsite_serial')}>
                  <TextField
                    {...register('serial', {
                      validate: {
                        isUnassigned: async (value) => {
                          const isValidSerial = await debouncedIsValidSerial(value);
                          if (isValidSerial)
                            return;

                          return t('ui_controllermanager_servicenow_createsite_validation_unassigned_controller', { value: value })
                        }
                      }
                    })}
                    error={errors.serial?.message}
                  />
                </FormElement>

                <FormElement label={t('ui_controllermanager_servicenow_createsite_controllerType')}>
                  <Controller
                    control={control}
                    name="controllerType"
                    render={({ field }) => (
                      <div style={{ display: "flex", gap: "4px" }}>
                        {field.value}
                      </div>)} 
                  />
                </FormElement>

                <FormElement label={t('ui_controllermanager_servicenow_createsite_buid')}>
                  <Controller
                    control={control}
                    name='buidId'
                    rules={{
                      validate: {
                        validValue: (value, form) => {
                          if (!value) return t('ui_controllermanager_servicenow_createsite_validation_dropdown', { value: form.buid })
                          else return
                        }
                      }
                    }}
                    render={({ field }) => (
                      <BuidDropdown
                        itemClicked={({ buidId }) => {
                          buidId && field.onChange(+buidId);
                        }}
                        selected={field.value?.toString()}
                        displayPath='name'
                        valuePath='buidId'
                      />
                    )}
                  />
                  <ErrorText>
                    {errors.buidId?.message}
                  </ErrorText>
                </FormElement>

                <FormElement label={t('ui_controllermanager_servicenow_createsite_accesstag')}>
                  <Controller
                    control={control}
                    name="accessTag"
                    rules={{
                      validate: {
                        isRequired: (value) => {
                          if (!value) return t('ui_controllermanager_servicenow_createsite_validation_required')
                          else return
                        }
                      }
                    }}
                    render={({ field }) => (
                      <CustomerDropdown
                        itemClicked={({ customerId }) => {
                          customerId && field.onChange(+customerId)
                          setAccessTagWarning('')
                        }}
                        selected={field.value?.toString()}
                      />
                    )} />
                  <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                    <ErrorText>
                      {errors.accessTag?.message}
                    </ErrorText>
                    <WarningText className='warning-text-preline'>
                      {accessTagWarning && accessTagWarning}
                    </WarningText>
                  </div>
                </FormElement>

                <FormElement label={t('ui_controllermanager_servicenow_createsite_soldto')}>
                  <div style={{ display: "flex", gap: "4px" }}>
                    <TextField
                      {...register('soldTo')}
                      onChange={async (e) => await tryGetAccessTagBySoldTo(e.target.value)}
                    />
                  </div>
                </FormElement>

                {!isMTDevice && (
                  <>
                    <FormElement label={t('ui_controllermanager_servicenow_createsite_shipTo')}>
                      <div style={{ display: "flex", gap: "4px" }}>
                        <TextField
                          {...register('shipTo')}
                          onChange={async (e) => await checkIfShipToInUse(e.target.value)}
                        />
                        <Button icon={'fa-search'} onClick={() => setShipToSearchMode(true)} disabled={!watchSoldTo} />
                      </div>
                      {shipToInUseWarning &&
                        <WarningText>
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <span>ShipTo already in use on site</span>
                            <a href={shipToInUseWarning.url} target='_blank' rel='noreferrer'>{shipToInUseWarning.text}</a>
                          </div>
                        </WarningText>
                      }
                    </FormElement>
                    <FormElement label={t('ui_controllermanager_servicenow_createsite_product')}>
                      <Controller
                        control={control}
                        name="productId"
                        rules={{
                          validate: {
                            validValue: (value, form) => {
                              if (value === null) return t('ui_controllermanager_servicenow_createsite_validation_dropdown', { value: form.product })
                              else return
                            }
                          }
                        }}
                        render={({ field }) => (
                          <ProductDropdown
                            canSelectBlankValue
                            itemClicked={({ productId }) => {
                              productId && field.onChange(+productId);
                            }}
                            selected={field.value?.toString()}
                            displayPath='name'
                            valuePath='productId'
                          />
                        )}
                      />
                      <ErrorText>
                        {errors.productId?.message}
                      </ErrorText>
                    </FormElement>
                    <FormElement label={t('ui_controllermanager_servicenow_createsite_tankcapacity')}>
                      <div className='tank-min-max-component'>
                        <div className='tank-value'>
                          <TextField
                            {...register('tankCapacity', {
                              valueAsNumber: true,
                              min: { value: 0, message: 'Value must be greater than or equal to 0' },
                              validate: {
                                mustBeLargerThanMin: (value) => {                                  
                                  if (parseInt(value) <= 0) return 'Value must be greater than or equal to 0'
                                  else return
                                }
                              }
                            })}
                            type='number'
                            onChange={(e) => {
                              const roundedValue = Math.round(parseInt(e.target?.value)).toString()
                              setValue('tankCapacity', roundedValue)
                              const values = getValues();
                              setValue('tankMinimum', ConvertPercentageToValue(values.tankMinimumPercentage, roundedValue))
                              setValue('tankMaximum', ConvertPercentageToValue(values.tankMaximumPercentage, roundedValue))
                            }}
                          />
                        </div>
                        <div className='capacity-unit'>
                          <Controller
                            control={control}
                            name="unitId"
                            render={({ field }) => (
                              <UnitDropdown
                                className='unit-dropdown'
                                error={!!errors.unitId?.message}
                                itemClicked={async ({ unitId }) => {
                                  unitId && field.onChange(+unitId)
                                }
                                }
                                selected={field.value?.toString()}
                                valuePath='unitId'
                                displayPath='translationKey'
                              />
                            )} />
                        </div>
                      </div>
                      <ErrorText>
                        {errors.tankCapacity?.message}
                      </ErrorText>
                    </FormElement>
                    <FormElement label={t('ui_controllermanager_servicenow_createsite_tankmaximum')}>
                      <div className='tank-min-max-component'>
                        <div className='tank-value'>
                          <TextField
                            {...register('tankMaximum', {
                              valueAsNumber: true,
                              min: { value: watchTankMin, message: 'Value must be greater than min value' },
                              max: { value: watchCapacity, message: 'Value must be lesser than or equal to capacity' },
                              validate: {
                                mustBeLargerThanMin: (value) => {
                                  if (value && watchTankMin && value <= watchTankMin) return 'Max value must be larger than min'
                                  else return
                                }
                              }
                            })}
                            type='number'
                            onChange={(e) => {
                              const roundedValue = Math.round(parseInt(e.target?.value)).toString()
                              setValue('tankMaximum', roundedValue)
                              
                              const values = getValues();
                              setValue('tankMaximumPercentage', ConvertValueToPercentage(roundedValue, values.tankCapacity))
                              trigger()
                            }}

                          />
                        </div>
                        <div className='tank-percentage'>
                          <TextField
                            {...register('tankMaximumPercentage', {
                              valueAsNumber: true,
                              min: { value: 0, message: 'Percentage must be greater than or equal to 0' },
                              max: { value: 100, message: 'Percentage must be lesser than or equal to 100' },
                              validate: {
                                mustBeLargerThanMin: (value, form) => {
                                  if (value && form.tankMinimumPercentage && value <= form.tankMinimumPercentage) return 'Max percentage must be larger than min'
                                  else return
                                }
                              }
                            })}
                            type='number'
                            icon={<Icon name='fa-percent'></Icon>}
                            onChange={(e) => {
                              const roundedValue = Math.round(parseInt(e.target?.value)).toString()
                              setValue('tankMaximumPercentage', roundedValue)

                              const values = getValues();
                              setValue('tankMaximum', ConvertPercentageToValue(roundedValue, values.tankCapacity))
                              trigger()
                            }}

                          />
                        </div>
                      </div>
                      <ErrorText className='multiple-error-view'>
                        <p>{errors.tankMaximum?.message}</p>
                        <p>{errors.tankMaximumPercentage?.message}</p>
                      </ErrorText>
                    </FormElement>
                </>
                )}

                <FormElement label={isMTDevice ? t('ui_controllermanager_servicenow_createsite_socminimum') : t('ui_controllermanager_servicenow_createsite_tankminimum')}>
                  <div className='tank-min-max-component'>
                    {!isMTDevice && (
                      <div className='tank-value'>
                        <TextField
                          {...register('tankMinimum', {
                            valueAsNumber: true,
                            min: { value: 0, message: 'Value must be greater than or equal to 0' },
                            max: { value: watchTankMax, message: 'Value must be less than max value' },
                            validate: {
                              mustBeLessThanMax: (value) => {
                                if (value && watchTankMax && value >= watchTankMax) return 'Min value must be smaller than max'
                                else return
                              }
                            }
                          })}
                          type='number'
                          onChange={(e) => {
                            const roundedValue = Math.round(parseInt(e.target?.value)).toString()
                            setValue('tankMinimum', roundedValue)

                            const values = getValues();
                            setValue('tankMinimumPercentage', ConvertValueToPercentage(roundedValue, values.tankCapacity))
                            trigger()
                          }}
                        />
                      </div>
                    )}
                    
                    <div className='tank-percentage'>
                      <TextField
                        {...register('tankMinimumPercentage', {
                          valueAsNumber: true,
                          min: { value: 0, message: 'Percentage must be greater than or equal to 0' },
                          max: { value: 100, message: 'Percentage must be lesser than or equal to 100' },
                          validate: {
                            mustLessThanMaxPercentage: (value, form) => {
                              if (!isMTDevice && value && form.tankMaximumPercentage && value >= form.tankMaximumPercentage) return 'Min percentage must be lower than max'                              
                              else return
                            }
                          }
                        })}
                        type='number'
                        onChange={(e) => {
                          const roundedValue = Math.round(parseInt(e.target?.value)).toString()
                          setValue('tankMinimumPercentage', roundedValue)

                          if (!isMTDevice) {
                            const values = getValues();
                            setValue('tankMinimum', ConvertPercentageToValue(roundedValue, values.tankCapacity))
                          }
                          trigger()
                        }}
                        icon={<Icon name='fa-percent'></Icon>}
                      />
                    </div>
                  </div>
                  
                  <ErrorText className='multiple-error-view'>
                    <p>{errors.tankMinimum?.message}</p>
                    <p>{errors.tankMinimumPercentage?.message}</p>
                  </ErrorText>
                </FormElement>                

                <FormElement label={t('ui_controllermanager_servicenow_createsite_externalusers')} direction="column">
                  <Controller
                    control={control}
                    name="externalUsersWithAccount"
                    render={({ field }) => (
                      <div style={{ display: 'flex', gap: '4px' }}>
                        {field.value}
                      </div>)} />
                </FormElement>

                <FormElement label={t('ui_controllermanager_servicenow_createsite_yarausers')} direction="column">
                  <Controller
                    control={control}
                    name="yaraUsers"
                    render={({ field }) => (
                      <div style={{ display: 'flex', gap: '4px' }}>
                        {field.value}
                      </div>)} />
                </FormElement>
                <div className="card-footer">
                  <div style={{ display: 'flex', gap: '4px', justifyContent: 'center' }}>
                    {newlyCreatedSite ? (
                        <>
                          <div style={{ display: 'flex', flexDirection: 'column', margin: '20px' }}>
                            <a href={newlyCreatedSite.url}>{newlyCreatedSite.text}</a>
                          </div>
                        </>
                      )
                      : (
                        <div style={{ display: 'flex', gap: '4px', justifyContent: 'center', flexDirection: "column" }}>
                          {createSiteError &&
                            <Card>
                              <CardContent>
                                <div style={{ display: "flex", justifyContent: "space-around" }}>
                                  <ErrorText>{createSiteError}</ErrorText>
                                </div>
                              </CardContent>
                            </Card>}
                        </div>
                      )}
                  </div>
                </div>
                {!newlyCreatedSite && <CardFooter className='create-new-site-card-footer'>
                  <div style={{ display: "flex", gap: "4px", justifyContent: 'space-around' }}>
                    <Button onClick={() => ctx.setActiveSlideoutComponent(ActiveSlideoutComponent.None)} variant='secondary'>Cancel</Button>
                    {ticketFound && <Button type='submit' icon={isSubmitting ? 'fa-spinner fa-pulse' : ''} disabled={isSubmitting || !isValid}>Create Site</Button>}
                  </div>
                </CardFooter>}
              </form>
            </>)}
            {!ticketFound &&  <CardFooter className='create-new-site-card-footer'>
                  <div style={{ display: "flex", gap: "4px", justifyContent: 'space-around' }}>
                    <Button onClick={() => ctx.setActiveSlideoutComponent(ActiveSlideoutComponent.None)} variant='secondary'>Cancel</Button>
                    </div>
                </CardFooter>}
      </CardContent>

    </div>);
};

export const CreateSiteFromTicket = memo(CreateSiteFromTicketComponent);
