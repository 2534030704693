import { useState } from "react";
import { ActiveSlideoutComponent, ControllerManagerContext} from "../contexts/controller-manager-context";

export default function useControllerManagerContextValues(): ControllerManagerContext {

    const [activeSlideoutComponent, setActiveSlideoutComponent] = useState<ActiveSlideoutComponent>(ActiveSlideoutComponent.None)
    
    return {
        activeSlideoutComponent,
        setActiveSlideoutComponent
    }
}