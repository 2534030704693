import { useQuery } from '$lib/hooks/fetch-utillities';
import {
  UnitsQueryReactDocument,
  UnitsQueryReactQuery,
} from '$typings/graphql-codegen';
import React, { FC, ReactElement } from 'react';
import Dropdown from '../dropdown/dropdown.react';

type UnitDropdownItem = UnitsQueryReactQuery['units'][0];

interface IUnitDropdownProps {
    selected?: UnitDropdownItem | string;
    valuePath?: string;
    displayPath?: string;
    itemClicked?: (params: { unitId: string | undefined; name?: string }) => void;
    topElement?: string;
    disabled?: boolean;
    placeholder?: string
    error?: boolean;
    replaceTopElement?: (query: string) => ReactElement;
    className?: string;
  }

  const UnitDropdown: FC<IUnitDropdownProps> = ({
    selected,
    itemClicked,
    topElement,
    disabled,
    placeholder,
    error,
    replaceTopElement,
    className,
    valuePath,
    displayPath
  }) => {

    const { data, loading } = useQuery(UnitsQueryReactDocument);
  
    return (
      <Dropdown<UnitDropdownItem>
        items={data?.units}
        className={className}
        selected={selected}
        loading={loading}
        valuePath={valuePath ?? 'unitId'}
        displayPath={displayPath ?? 'translationKey'}
        itemClicked={(params) =>
          itemClicked &&
          itemClicked({
            unitId: params?.value?.toString(),
            name: params?.text,
          })}
        placeholder={placeholder}
        topElement={topElement}
        replaceTopElement={replaceTopElement}
        error={error}
        disabled={disabled}
        translate={true}
      />
    );
  };

  export default UnitDropdown;